"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
var _user = require("@/api/user");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'CouponUser',
  filters: {
    failFilter: function failFilter(status) {
      var statusMap = {
        receive: this.$t('coupon.record.index.5u3iom4e6a40'),
        send: this.$t('coupon.record.index.5u3iom4e6c40'),
        give: this.$t('coupon.record.index.5u3iom4e6eo0'),
        new: this.$t('coupon.record.index.5u3iom4e6g00'),
        buy: this.$t('coupon.record.index.5u3iom4e6hk0')
      };
      return statusMap[status];
    },
    statusFilter: function statusFilter(status) {
      var statusMap = {
        0: this.$t('coupon.record.index.5u3iom4e5lg0'),
        1: this.$t('coupon.record.index.5u3iom4e5js0'),
        2: this.$t('coupon.record.index.5u3iom4e5n80')
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      Loading: false,
      roterPre: _settings.roterPre,
      imgList: [],
      tableFromIssue: {
        page: 1,
        limit: 20,
        uid: '',
        name: '',
        status: ''
      },
      issueData: {
        data: [],
        total: 0
      },
      loading: false,
      options: []
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['merchant:coupon:user:page:list'])) this.getIssueList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    seachList: function seachList() {
      this.tableFromIssue.page = 1;
      this.getIssueList();
    },
    // 列表
    getIssueList: function getIssueList() {
      var _this = this;
      this.Loading = true;
      (0, _marketing.couponUserListApi)(this.tableFromIssue).then(function (res) {
        _this.issueData.data = res.list;
        _this.issueData.total = res.total;
        // this.issueData.data.map((item) => {
        //   this.imgList.push(item.user.avatar)
        // })
        _this.Loading = false;
      }).catch(function (res) {
        _this.Loading = false;
        _this.$message.error(res.message);
      });
    },
    pageChangeIssue: function pageChangeIssue(page) {
      this.tableFromIssue.page = page;
      this.getIssueList();
    },
    handleSizeChangeIssue: function handleSizeChangeIssue(val) {
      this.tableFromIssue.limit = val;
      this.getIssueList();
    }
  }
};