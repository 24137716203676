var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "formValidate",
      staticClass: "demo-formValidate",
      attrs: {
        model: _vm.formValidate,
        rules: _vm.rules,
        "label-width": "100px",
      },
    },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("product.comment.creatComment.5u2qzs254b80"),
            prop: "productId",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["merchant:product:page:list"],
                  expression: "['merchant:product:page:list']",
                },
              ],
              staticClass: "upLoadPicBox",
              on: { click: _vm.changeGood },
            },
            [
              _vm.formValidate.productId
                ? _c("div", { staticClass: "pictrue" }, [
                    _c("img", { attrs: { src: _vm.image } }),
                  ])
                : _c("div", { staticClass: "upLoad" }, [
                    _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
                  ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          staticClass: "labeltop",
          attrs: {
            label: _vm.$t("product.comment.creatComment.5u2qzs254xs0"),
            required: "",
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.ManyAttrValue,
                "tooltip-effect": "dark",
                size: "mini",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("product.comment.creatComment.5u2qzs255300"),
                  width: "50",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: scope.row },
                            nativeOn: {
                              change: function ($event) {
                                return _vm.changeType(scope.row)
                              },
                            },
                            model: {
                              value: _vm.radio,
                              callback: function ($$v) {
                                _vm.radio = $$v
                              },
                              expression: "radio",
                            },
                          },
                          [_vm._v("  ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.manyTabDate
                ? _vm._l(_vm.manyTabDate, function (item, iii) {
                    return _c("el-table-column", {
                      key: iii,
                      attrs: {
                        align: "center",
                        label: _vm.manyTabTit[iii].title,
                        "min-width": "80",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", {
                                  staticClass: "priceBox",
                                  domProps: {
                                    textContent: _vm._s(scope.row[iii]),
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: _vm.$t("product.comment.creatComment.5u2qzs2554s0"),
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "upLoadPicBox" },
                          [
                            _c("el-image", {
                              staticClass: "tabPic",
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm._l(_vm.attrValue, function (item, iii) {
                return _c("el-table-column", {
                  key: iii,
                  attrs: {
                    label: _vm.formThead[iii].title,
                    align: "center",
                    "min-width": "140",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c("span", {
                              staticClass: "priceBox",
                              domProps: { textContent: _vm._s(row[iii]) },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("product.comment.creatComment.5u2qzs2557w0"),
            prop: "nickname",
          },
        },
        [
          _c("el-input", {
            attrs: { type: "text" },
            model: {
              value: _vm.formValidate.nickname,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formValidate,
                  "nickname",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formValidate.nickname",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("product.comment.creatComment.5u2qzs2559s0"),
            prop: "comment",
          },
        },
        [
          _c("el-input", {
            attrs: { type: "textarea" },
            model: {
              value: _vm.formValidate.comment,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formValidate,
                  "comment",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formValidate.comment",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          staticClass: "productScore",
          attrs: {
            label: _vm.$t("product.comment.creatComment.5u2qzs255e00"),
            prop: "star",
          },
        },
        [
          _c("el-rate", {
            model: {
              value: _vm.formValidate.star,
              callback: function ($$v) {
                _vm.$set(_vm.formValidate, "star", $$v)
              },
              expression: "formValidate.star",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("product.comment.creatComment.5u2qzs255g00"),
            prop: "avatar",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: [
                    "merchant:category:list:tree",
                    "merchant:product:page:list",
                  ],
                  expression:
                    "['merchant:category:list:tree', 'merchant:product:page:list']",
                },
              ],
              staticClass: "upLoadPicBox",
              on: {
                click: function ($event) {
                  return _vm.modalPicTap("1")
                },
              },
            },
            [
              _vm.formValidate.avatar
                ? _c("div", { staticClass: "pictrue" }, [
                    _c("img", { attrs: { src: _vm.formValidate.avatar } }),
                  ])
                : _c("div", { staticClass: "upLoad" }, [
                    _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
                  ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: { label: _vm.$t("product.comment.creatComment.5u2qzs255ho0") },
        },
        [
          _c(
            "div",
            { staticClass: "acea-row" },
            [
              _vm._l(_vm.formValidate.pics, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "pictrue",
                    attrs: { draggable: "false" },
                    on: {
                      dragstart: function ($event) {
                        return _vm.handleDragStart($event, item)
                      },
                      dragover: function ($event) {
                        $event.preventDefault()
                        return _vm.handleDragOver($event, item)
                      },
                      dragenter: function ($event) {
                        return _vm.handleDragEnter($event, item)
                      },
                      dragend: function ($event) {
                        return _vm.handleDragEnd($event, item)
                      },
                    },
                  },
                  [
                    _c("img", { attrs: { src: item } }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-error btndel",
                      on: {
                        click: function ($event) {
                          return _vm.handleRemove(index)
                        },
                      },
                    }),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.formValidate.pics < 10
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: [
                            "merchant:category:list:tree",
                            "merchant:product:page:list",
                          ],
                          expression:
                            "['merchant:category:list:tree', 'merchant:product:page:list']",
                        },
                      ],
                      staticClass: "upLoadPicBox",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("2")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "upLoad" }, [
                        _c("i", {
                          staticClass: "el-icon-camera cameraIconfont",
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.resetForm("formValidate")
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("product.comment.creatComment.5u2qzs255jc0"))
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["merchant:product:update"],
                  expression: "['merchant:product:update']",
                },
              ],
              attrs: { size: "mini", type: "primary", loading: _vm.loadingbtn },
              on: {
                click: function ($event) {
                  return _vm.submitForm("formValidate")
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("product.comment.creatComment.5u2qzs255kw0"))
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }