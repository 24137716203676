var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              ref: "headerBox",
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-tabs",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["merchant:group:product:list"],
                      expression: "['merchant:group:product:list']",
                    },
                  ],
                  on: {
                    "tab-click": function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.auditStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "auditStatus", $$v)
                    },
                    expression: "tableFrom.auditStatus",
                  },
                },
                _vm._l(_vm.headeNum, function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: { label: item.name, name: item.type },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["merchant:group:product:list"],
                      expression: "['merchant:group:product:list']",
                    },
                  ],
                  staticClass: "container",
                },
                [
                  _c(
                    "el-form",
                    { attrs: { size: "small", inline: "" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "marketing.group.groupList.index.5u4e0gmmlpg0"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "marketing.group.groupList.index.5u4e0gmmogw0"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.proName,
                                        callback: function ($$v) {
                                          _vm.proName = $$v
                                        },
                                        expression: "proName",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getList(1)
                                          },
                                        },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "marketing.group.groupList.index.5u4e0gmmp000"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "marketing.group.groupList.index.5u4e0gmmp340"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.activityName,
                                        callback: function ($$v) {
                                          _vm.activityName = $$v
                                        },
                                        expression: "activityName",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: [
                                              "merchant:group:product:list",
                                            ],
                                            expression:
                                              "['merchant:group:product:list']",
                                          },
                                        ],
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getList(1)
                                          },
                                        },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline",
                                  attrs: {
                                    label: _vm.$t(
                                      "marketing.group.groupList.index.5u4e0gmmp5k0"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: [
                                            "merchant:group:product:list",
                                          ],
                                          expression:
                                            "['merchant:group:product:list']",
                                        },
                                      ],
                                      staticClass: "selWidth",
                                      attrs: {
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "marketing.group.groupList.index.5u4e0gmmp8w0"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.getList(1)
                                        },
                                      },
                                      model: {
                                        value: _vm.tableFrom.proStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tableFrom,
                                            "proStatus",
                                            $$v
                                          )
                                        },
                                        expression: "tableFrom.proStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "marketing.group.groupList.index.5u4e0gmmpeg0"
                                          ),
                                          value: 1,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "marketing.group.groupList.index.5u4e0gmmpi80"
                                          ),
                                          value: 0,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline",
                                  attrs: {
                                    label: _vm.$t(
                                      "marketing.group.groupList.index.5u4e0gmmpkc0"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: [
                                            "merchant:group:product:list",
                                          ],
                                          expression:
                                            "['merchant:group:product:list']",
                                        },
                                      ],
                                      staticClass: "selWidth",
                                      attrs: {
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "marketing.group.groupList.index.5u4e0gmmp8w0"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.getList(1)
                                        },
                                      },
                                      model: {
                                        value: _vm.tableFrom.activityStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tableFrom,
                                            "activityStatus",
                                            $$v
                                          )
                                        },
                                        expression: "tableFrom.activityStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "marketing.group.groupList.index.5u4e0gmmpn40"
                                          ),
                                          value: 1,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "marketing.group.groupList.index.5u4e0gmmpqg0"
                                          ),
                                          value: 2,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["merchant:group:product:add"],
                      expression: "['merchant:group:product:add']",
                    },
                  ],
                  staticClass: "mr10",
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onEdit(0)
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("marketing.group.groupList.index.5u4eauvkuk00")
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tableFrom.auditStatus === "3",
                      expression: "tableFrom.auditStatus === '3'",
                    },
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["merchant:group:product:delete"],
                      expression: "['merchant:group:product:delete']",
                    },
                  ],
                  attrs: {
                    size: "small",
                    disabled: !_vm.multipleSelection.length,
                  },
                  on: { click: _vm.batchDel },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("marketing.group.groupList.index.5u4eauvky040")
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.tableFrom.auditStatus === "2"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["merchant:group:product:down"],
                          expression: "['merchant:group:product:down']",
                        },
                      ],
                      attrs: {
                        size: "small",
                        disabled: !_vm.multipleSelection.length,
                      },
                      on: { click: _vm.batchDown },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "marketing.group.groupList.index.5u4eauvky7g0"
                            )
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "row-key": "id",
                "header-cell-style": { fontWeight: "bold" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "45",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.group.groupList.index.5u4e0gmmpu40"),
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.group.groupList.index.5u4e0gmmpw80"),
                  prop: "name",
                  "min-width": "180",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "categoryName",
                  label: _vm.$t("marketing.group.groupList.index.5u4e0gmmpz00"),
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "activityName",
                  label: _vm.$t("marketing.group.groupList.index.5u4e0gmmq1o0"),
                  "min-width": "150",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "price",
                  label: _vm.$t("marketing.group.groupList.index.5u4e0gmmq4g0"),
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "groupPrice",
                  label: _vm.$t("marketing.group.groupList.index.5u4e0gmmq6c0"),
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sort",
                  label: _vm.$t("marketing.group.groupList.index.5u4e0gmmq8c0"),
                  "min-width": "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.group.groupList.index.5u4e0gmmq9w0"),
                  "min-width": "70",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isShow
                          ? _c("el-tag", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4e0gmmpeg0"
                                  )
                                )
                              ),
                            ])
                          : _c("el-tag", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4e0gmmpi80"
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.group.groupList.index.5u4e0gmmqcg0"),
                  fixed: "right",
                  "min-width": "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.activityStatus === 0
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4e0gmmqgo0"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.activityStatus === 1
                          ? _c("el-tag", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4e0gmmpn40"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.activityStatus === 2
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4e0gmmpqg0"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.group.groupList.index.5u4e0gmmqic0"),
                  fixed: "right",
                  "min-width": "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auditStatus === 1
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4e0gmmqkg0"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.auditStatus === 2
                          ? _c("el-tag", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4e0gmmqm00"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.auditStatus === 3
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4e0gmmqoc0"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.tableFrom.auditStatus === "3"
                ? _c("el-table-column", {
                    attrs: {
                      prop: "reason",
                      label: _vm.$t(
                        "marketing.group.groupList.index.5u4e0gmmqpw0"
                      ),
                      fixed: "right",
                      "min-width": "120",
                      "show-overflow-tooltip": true,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.group.groupList.index.5u4e0gmmqsg0"),
                  "min-width": "180",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:group:product:list"],
                                expression: "['merchant:group:product:list']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAudit(scope.row, 2)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4ecufuu480"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.tableFrom.auditStatus === "2"
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["merchant:group:product:list"],
                                    expression:
                                      "['merchant:group:product:list']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAudit(scope.row, 3)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "marketing.group.groupList.index.5u4ecufuurg0"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.tableFrom.auditStatus === "2" &&
                                  scope.row.isShow,
                                expression:
                                  "tableFrom.auditStatus === '2' && scope.row.isShow",
                              },
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:group:product:down"],
                                expression: "['merchant:group:product:down']",
                              },
                            ],
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                _vm.handleDown(
                                  scope.row,
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4eauvkya80"
                                  )
                                )
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4ecufuuuo0"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.tableFrom.auditStatus === "2" &&
                                  !scope.row.isShow,
                                expression:
                                  "tableFrom.auditStatus === '2' && !scope.row.isShow",
                              },
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:group:product:up"],
                                expression: "['merchant:group:product:up']",
                              },
                            ],
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                _vm.handleDown(
                                  scope.row,
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4eauvkyd40"
                                  )
                                )
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4ecufuuwg0"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.tableFrom.auditStatus === "1",
                                expression: "tableFrom.auditStatus === '1'",
                              },
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:group:product:withdraw"],
                                expression:
                                  "['merchant:group:product:withdraw']",
                              },
                            ],
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleWithdraw(scope.row)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4ecufuuyg0"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.tableFrom.auditStatus === "3"
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.tableFrom.auditStatus === "3",
                                    expression: "tableFrom.auditStatus === '3'",
                                  },
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["merchant:group:product:delete"],
                                    expression:
                                      "['merchant:group:product:delete']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "marketing.group.groupList.index.5u4ecufuv040"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.tableFrom.auditStatus === "3",
                                expression: "tableFrom.auditStatus === '3'",
                              },
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:group:product:add"],
                                expression: "['merchant:group:product:add']",
                              },
                            ],
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(1, scope.row)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.groupList.index.5u4eauvkygc0"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            direction: "rtl",
            visible: _vm.dialogVisible,
            size: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: function ($event) {
              return _vm.close("ruleForm")
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "demo-drawer_title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.isShow < 3
                      ? _vm.$t("marketing.group.groupList.index.5u4e0gmmqyw0")
                      : _vm.$t("marketing.group.groupList.index.5u4e0gmmr0o0")
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "demo-drawer__content",
            },
            [
              _c("div", { staticClass: "description" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("marketing.group.groupList.index.5u4e0gmmr200")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "acea-row" }, [
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("marketing.group.groupList.index.5u4e0gmmp000")
                        )
                      ),
                    ]),
                    _vm._v(_vm._s(_vm.groupInfo.activityName) + "\n          "),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("marketing.group.groupList.index.5u4e0gmmpkc0")
                        )
                      ),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm._f("activityStatusFilter")(
                          _vm.groupInfo.activityStatus
                        )
                      ) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("marketing.group.groupList.index.5u4e0gmmr480")
                        )
                      ),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.groupInfo.auditStatus == 1
                          ? _vm.$t(
                              "marketing.group.groupList.index.5u4e0gmmqkg0"
                            )
                          : _vm.groupInfo.auditStatus == 2
                          ? _vm.$t(
                              "marketing.group.groupList.index.5u4e0gmmr680"
                            )
                          : _vm.$t(
                              "marketing.group.groupList.index.5u4e0gmmqoc0"
                            )
                      ) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("marketing.group.groupList.index.5u4e0gmmr800")
                        )
                      ),
                    ]),
                    _vm._v(_vm._s(_vm.groupInfo.categoryName) + "\n          "),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("div", { staticClass: "description" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("marketing.group.groupList.index.5u4e0gmmr9k0")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "acea-row mb20" }, [
                  _c("div", { staticClass: "description-term mb20" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("marketing.group.groupList.index.5u4e0gmmlpg0")
                        )
                      ),
                    ]),
                    _vm._v(_vm._s(_vm.groupInfo.name) + "\n          "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          ref: "tableList",
                          staticStyle: { width: "100%" },
                          attrs: {
                            "row-key": "id",
                            data: _vm.groupInfo.attrValue,
                            size: "mini",
                            border: "",
                            "default-expand-all": "",
                            "tree-props": { children: "children" },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "140",
                              label: _vm.$t(
                                "marketing.group.groupList.index.5u4e0gmmr9k0"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", { staticClass: "acea-row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "demo-image__preview mr10",
                                        },
                                        [
                                          _c("el-image", {
                                            staticStyle: {
                                              width: "36px",
                                              height: "36px",
                                            },
                                            attrs: {
                                              src: scope.row.image,
                                              "preview-src-list": [
                                                scope.row.image,
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "row_title line2" },
                                        [_vm._v(_vm._s(scope.row.sku))]
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "otPrice",
                              label: _vm.$t(
                                "marketing.group.groupList.index.5u4e0gmmrb40"
                              ),
                              width: "120",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "quotaShow",
                              label: _vm.$t(
                                "marketing.group.groupList.index.5u4e0gmmw6g0"
                              ),
                              width: "80",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "quota",
                              label: _vm.$t(
                                "marketing.group.groupList.index.5u4e0gmmwh00"
                              ),
                              width: "120",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "price",
                              label: _vm.$t(
                                "marketing.group.groupList.index.5u4e0gmmwj00"
                              ),
                              "min-width": "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input-number", {
                                      staticClass: "input_width",
                                      attrs: {
                                        disabled: _vm.isShow < 3,
                                        type: "number",
                                        precision: 2,
                                        min: 0,
                                        max: 99999,
                                        controls: false,
                                      },
                                      model: {
                                        value: scope.row.price,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "price", $$v)
                                        },
                                        expression: "scope.row.price",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShow === 3,
                      expression: "isShow === 3",
                    },
                  ],
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["merchant:group:product:price"],
                          expression: "['merchant:group:product:price']",
                        },
                      ],
                      staticClass: "mb20",
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.setPrice(
                            _vm.groupInfo.id,
                            _vm.groupInfo.attrValue
                          )
                        },
                      },
                    },
                    [_vm._v("保存活动价\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }