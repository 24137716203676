"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.capitalFlowExportApi = capitalFlowExportApi;
exports.capitalFlowLstApi = capitalFlowLstApi;
exports.closingApplyApi = closingApplyApi;
exports.closingBaseInfoApi = closingBaseInfoApi;
exports.closingDetailApi = closingDetailApi;
exports.closingRecordListApi = closingRecordListApi;
exports.dayStatementApi = dayStatementApi;
exports.downloadFinancialApi = downloadFinancialApi;
exports.exportFundsExcel = exportFundsExcel;
exports.finaHeaderDataApi = finaHeaderDataApi;
exports.financialDetailApi = financialDetailApi;
exports.financialLstApi = financialLstApi;
exports.getStatisticsApi = getStatisticsApi;
exports.monthStatementApi = monthStatementApi;
exports.reconciliationListApi = reconciliationListApi;
exports.reconciliationMarkApi = reconciliationMarkApi;
exports.reconciliationOrderApi = reconciliationOrderApi;
exports.reconciliationRefundApi = reconciliationRefundApi;
exports.reconciliationStatusApi = reconciliationStatusApi;
exports.transferMarkApi = transferMarkApi;
exports.transferRecordsExportApi = transferRecordsExportApi;
var _request = _interopRequireDefault(require("@/utils/request"));
var _req = _interopRequireDefault(require("./req"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * @description 财务对账 -- 对账单列表
 */
function reconciliationListApi(data) {
  return _request.default.get("store/order/reconciliation/lst", data);
}

/**
 * @description 财务对账 -- 确认打款
 */
function reconciliationStatusApi(id, data) {
  return _request.default.post("store/order/reconciliation/status/".concat(id), data);
}

/**
 * @description 财务对账 -- 查看订单
 */
function reconciliationOrderApi(id, data) {
  return _request.default.get("store/order/reconciliation/".concat(id, "/order"), data);
}

/**
 * @description 财务对账 -- 退款订单
 */
function reconciliationRefundApi(id, data) {
  return _request.default.get("store/order/reconciliation/".concat(id, "/refund"), data);
}

/**
 * @description 财务对账 -- 备注
 */
function reconciliationMarkApi(id) {
  return _request.default.get("store/order/reconciliation/mark/".concat(id, "/form"));
}

/**
 * @description 资金流水 -- 列表
 */
function capitalFlowLstApi(params) {
  return (0, _request.default)({
    url: "admin/merchant/finance/funds/flow",
    method: 'get',
    params: params
  });
}

/**
 * @description 资金流水 -- 导出
 */
// export function exportFundsExcel(data) {
//   return request.get(`admin/merchant/export/funds/excel`, data);
// }
function exportFundsExcel(params) {
  return (0, _request.default)({
    url: "admin/merchant/export/funds/excel",
    method: 'get',
    params: params
  });
}
/**
 * @description 资金流水 -- 导出
 */
function capitalFlowExportApi(data) {
  return _request.default.get("financial_record/export", data);
}

/**
 * @description 转账记录 -- 导出
 */
function transferRecordsExportApi(data) {
  return _request.default.get("financial/export", data);
}

/**
 * @description 结算申请
 */
function closingApplyApi(data) {
  return (0, _request.default)({
    url: 'admin/merchant/finance/closing/apply',
    method: 'post',
    data: data
  });
}

/**
 * @description 结算记录分页列表
 */
function closingRecordListApi(params) {
  return (0, _request.default)({
    url: 'admin/merchant/finance/closing/record/list',
    method: 'GET',
    params: params
  });
}

/**
 * @description 获取结算申请基础信息
 */
function closingBaseInfoApi() {
  return (0, _request.default)({
    url: "admin/merchant/finance/closing/base/info",
    method: 'GET'
  });
}

/**
 * @description 结算记录详情
 */
function closingDetailApi(id) {
  return (0, _request.default)({
    url: "admin/merchant/finance/closing/record/detail/".concat(id),
    method: 'GET'
  });
}

/**
 * @description 账单管理 日帐单管理分页列表
 */
function dayStatementApi(params) {
  return (0, _request.default)({
    url: "admin/merchant/finance/daily/statement/list",
    method: 'get',
    params: params
  });
}

/**
 * @description 账单管理 月帐单管理分页列表
 */
function monthStatementApi(params) {
  return (0, _request.default)({
    url: "admin/merchant/finance/month/statement/list",
    method: 'get',
    params: params
  });
}

/**
 * @description 申请转账 -- 备注
 */
function transferMarkApi(id) {
  return _request.default.get("financial/mark/".concat(id, "/form"));
}

/**
 * @description 财务账单 -- 列表
 */
function financialLstApi(data) {
  return _request.default.get("financial_record/lst", data);
}

/**
 * @description 财务账单 -- 详情
 */
function financialDetailApi(type, data) {
  return _request.default.get("financial_record/detail/".concat(type), data);
}

/**
 * @description 财务账单 -- 头部数据
 */
function finaHeaderDataApi(data) {
  return _request.default.get("financial_record/title", data);
}

/**
 * @description 财务账单 -- 下载账单
 */
function downloadFinancialApi(type, data) {
  return _request.default.get("financial_record/detail_export/".concat(type), data);
}

/**
 * @description 资金流水 -- 统计数据
 */
function getStatisticsApi(data) {
  return _request.default.get("financial_record/count", data);
}