var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("div", {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          }),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { fontWeight: "bold" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "180" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t(
                    "maintain.logistics.companyList.index.5u3iqn6dntw0"
                  ),
                  "min-width": "150",
                  prop: "name",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "200",
                  label: _vm.$t(
                    "maintain.logistics.companyList.index.5u3iqn6docs0"
                  ),
                  prop: "code",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "100",
                  label: _vm.$t(
                    "maintain.logistics.companyList.index.5u3iqn6dof40"
                  ),
                  prop: "sort",
                  sortable: "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t(
                    "maintain.logistics.companyList.index.5u3iqn6doh00"
                  ),
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["admin:express:update:show"])
                          ? _c("el-switch", {
                              staticClass: "demo",
                              attrs: {
                                "active-value": true,
                                "inactive-value": false,
                                "active-text": _vm.$t(
                                  "maintain.logistics.companyList.index.5u3iqn6doiw0"
                                ),
                                "inactive-text": _vm.$t(
                                  "maintain.logistics.companyList.index.5u3iqn6doko0"
                                ),
                                disabled: true,
                              },
                              model: {
                                value: scope.row.isShow,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isShow", $$v)
                                },
                                expression: "scope.row.isShow",
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }