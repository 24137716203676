"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _permission = require("@/utils/permission");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'groupList',
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableForm: {
        page: 1,
        limit: 20,
        name: '',
        date: ''
      },
      name: '',
      fromList: this.$constants.fromList,
      merStarLevel: _jsCookie.default.get('merStarLevel')
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['merchant:group:activity:page'])) this.getList(1);
    if ((0, _permission.checkPermi)(['merchant:plat:product:category:cache:tree'])) this.$store.dispatch('product/getAdminProductClassify');
  },
  methods: {
    checkPermi: _permission.checkPermi,
    goOn: function goOn(id) {
      this.$router.push({
        path: "/marketing/group/creatActivity/".concat(id)
      });
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.tableForm.page = num ? num : this.tableForm.page;
      this.tableForm.name = encodeURIComponent(this.name);
      this.listLoading = true;
      (0, _marketing.groupActivityListApi)(this.tableForm).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    }
  }
};