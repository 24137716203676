"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.copyrightInfoApi = copyrightInfoApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 获取版权信息
 */
function copyrightInfoApi() {
  return (0, _request.default)({
    url: '/admin/merchant/copyright/get/company/info',
    method: 'get'
  });
}