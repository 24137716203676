"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _index = require("@/lang/index");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var state = {
  language: (0, _index.getLanguage)(),
  sidebar: {
    opened: _jsCookie.default.get('sidebarStatus') ? !!+_jsCookie.default.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  size: _jsCookie.default.get('size') || 'mini'
};
var mutations = {
  SET_LANGUAGE: function SET_LANGUAGE(state, language) {
    state.language = language;
    _jsCookie.default.set('language', language);
  },
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      _jsCookie.default.set('sidebarStatus', 1);
    } else {
      _jsCookie.default.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    _jsCookie.default.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_SIZE: function SET_SIZE(state, size) {
    state.size = size;
    _jsCookie.default.set('size', size);
  }
};
var actions = {
  setLanguage: function setLanguage(_ref, language) {
    var commit = _ref.commit;
    commit('SET_LANGUAGE', language);
  },
  toggleSideBar: function toggleSideBar(_ref2) {
    var commit = _ref2.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref3, _ref4) {
    var commit = _ref3.commit;
    var withoutAnimation = _ref4.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref5, device) {
    var commit = _ref5.commit;
    commit('TOGGLE_DEVICE', device);
  },
  setSize: function setSize(_ref6, size) {
    var commit = _ref6.commit;
    commit('SET_SIZE', size);
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};