"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'goodList',
  props: {
    handleNum: {
      type: String,
      default: ''
    },
    checked: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      templateRadio: 0,
      props: {
        children: 'child',
        label: 'name',
        value: 'id',
        emitPath: false
      },
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 1,
        cateId: '',
        keywords: '',
        type: '1'
      },
      imgList: [],
      multipleSelection: [],
      multipleSelectionAll: [],
      checkedPage: [],
      isChecked: false,
      isIndex: 0,
      idKey: 'id',
      checkBox: [],
      checkedIds: [] // 订单当前页选中的数据
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.tableFrom.page = 1;
    this.getList();
    if (!this.checked) return;
    this.checked.forEach(function (row) {
      _this.$refs.table.toggleRowSelection(row);
    });
  },
  methods: {
    onInput: function onInput(e) {
      this.$forceUpdate();
    },
    changeType: function changeType(v) {
      this.isChecked = v;
      var index = this.checkedPage.indexOf(this.tableFrom.page);
      this.isIndex = index;
      this.checkedPage.push(this.tableFrom.page);
      if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId(v);
    },
    changeOne: function changeOne(v, order) {
      if (v) {
        var index = this.checkedIds.indexOf(order.id);
        if (index === -1) {
          this.checkedIds.push(order.id);
          this.checkBox.push(order);
        }
      } else {
        var _index = this.checkedIds.indexOf(order.id);
        if (_index > -1) {
          this.checkedIds.splice(_index, 1);
          this.checkBox.splice(_index, 1);
        }
      }
    },
    syncCheckedId: function syncCheckedId(o) {
      var _this2 = this;
      var ids = this.tableData.data.map(function (v) {
        return v.id;
      });
      if (o) {
        this.tableData.data.forEach(function (item) {
          var index = _this2.checkedIds.indexOf(item.id);
          if (index === -1) {
            _this2.checkedIds.push(item.id);
            _this2.checkBox.push(item);
          }
        });
      } else {
        this.tableData.data.forEach(function (item) {
          var index = _this2.checkedIds.indexOf(item.id);
          if (index > -1) {
            _this2.checkedIds.splice(index, 1);
            _this2.checkBox.splice(index, 1);
          }
        });
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var _this3 = this;
      this.multipleSelection = val;
      setTimeout(function () {
        _this3.$selfUtil.changePageCoreRecordData(_this3.multipleSelectionAll, _this3.multipleSelection, _this3.tableData.data, function (e) {
          _this3.multipleSelectionAll = e;
        });
      }, 50);
    },
    // 设置选中的方法
    setSelectRow: function setSelectRow() {
      if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
        return;
      }
      // 标识当前行的唯一键的名称
      var idKey = this.idKey;
      var selectAllIds = [];
      this.multipleSelectionAll.forEach(function (row) {
        selectAllIds.push(row[idKey]);
      });
      this.$refs.table.clearSelection();
      for (var i = 0; i < this.tableData.data.length; i++) {
        if (selectAllIds.indexOf(this.tableData.data[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.table.toggleRowSelection(this.tableData.data[i], true);
        }
      }
    },
    ok: function ok() {
      this.$emit('getStoreItem', this.multipleSelectionAll);
    },
    getTemplateRow: function getTemplateRow(row) {
      this.$emit('getStoreItem', row);
    },
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _product.productLstApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.list;
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
        _this4.$nextTick(function () {
          this.setSelectRow(); // 调用跨页选中方法
        });
      }).catch(function (res) {
        _this4.listLoading = false;
        _this4.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      var _this5 = this;
      this.$selfUtil.changePageCoreRecordData(this.multipleSelectionAll, this.multipleSelection, this.tableData.data, function (e) {
        _this5.multipleSelectionAll = e;
      });
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this6 = this;
      this.$selfUtil.changePageCoreRecordData(this.multipleSelectionAll, this.multipleSelection, this.tableData.data, function (e) {
        _this6.multipleSelectionAll = e;
      });
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};