"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _userList = _interopRequireDefault(require("@/components/userList"));
var _userDetails = _interopRequireDefault(require("./userDetails.vue"));
var _permission = require("@/utils/permission");
var _validate = require("@/utils/validate");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'UserIndex',
  components: {
    detailUser: _userDetails.default
  },
  filters: {
    sexFilter: function sexFilter(status) {
      var statusMap = {
        0: this.$t('user.list.index.5u3d5tn57bk0'),
        1: this.$t('user.list.index.5u3d5tn57d80'),
        2: this.$t('user.list.index.5u3d5tn57f00'),
        3: this.$t('user.list.index.5u3d5tn57g80')
      };
      return statusMap[status];
    },
    typeFilter: function typeFilter(value) {
      var statusMap = {
        facebook: 'Facebook',
        twitter: 'Twitter',
        google: 'Google',
        email: 'Email',
        phone: 'Phone',
        visitor: this.$t('user.list.index.5u3d5tn59bc0')
      };
      return statusMap[value];
    }
  },
  data: function data() {
    return {
      registerTypeList: [{
        value: 'wechat',
        label: this.$t('user.list.index.5u3d5tn59cg0')
      }, {
        value: 'routine',
        label: this.$t('user.list.index.5u3d5tn59ds0')
      }, {
        value: 'h5',
        label: 'H5'
      }, {
        value: 'iosWx',
        label: this.$t('user.list.index.5u3d5tn59fk0')
      }, {
        value: 'androidWx',
        label: this.$t('user.list.index.5u3d5tn59gw0')
      }, {
        value: 'ios',
        label: 'ios'
      }],
      tableHeight: 0,
      formExtension: {
        image: '',
        spreadUid: '',
        userId: ''
      },
      ruleInline: {},
      extensionVisible: false,
      userVisible: false,
      levelInfo: '',
      pickerOptions: this.$timeOptions,
      loadingBtn: false,
      PointValidateForm: {
        integralType: 2,
        integralValue: 0,
        moneyType: 2,
        moneyValue: 0,
        uid: ''
      },
      loadingPoint: false,
      VisiblePoint: false,
      visible: false,
      userIds: '',
      dialogVisible: false,
      levelVisible: false,
      levelData: [],
      groupData: [],
      labelData: [],
      selData: [],
      labelPosition: 'right',
      collapse: false,
      props: {
        children: 'child',
        label: 'name',
        value: 'name',
        emitPath: false
      },
      propsCity: {
        children: 'child',
        label: 'name',
        value: 'name'
      },
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      nikename: '',
      userFrom: {
        registerType: '',
        sex: '',
        dateLimit: '',
        nikename: '',
        page: 1,
        limit: 15,
        phone: ''
      },
      grid: {
        xl: 8,
        lg: 12,
        md: 12,
        sm: 24,
        xs: 24
      },
      levelList: [],
      labelLists: [],
      groupList: [],
      selectedData: [],
      timeVal: [],
      dynamicValidateForm: {
        groupId: []
      },
      loading: false,
      groupIdFrom: [],
      selectionList: [],
      batchName: '',
      uid: 0,
      Visible: false,
      keyNum: 0,
      address: [],
      multipleSelectionAll: [],
      idKey: 'uid',
      card_select_show: false,
      checkAll: false,
      checkedCities: ['ID', this.$t('user.list.index.5u3d5tn590s0'), this.$t('user.list.index.5u3d5tn59240'), this.$t('user.list.index.5u3d5tn59j40'), this.$t('user.list.index.5u3d5tn59k40'), this.$t('user.list.index.5u3d5tn59lk0'), this.$t('user.list.index.5u3d5tn593c0'), this.$t('user.list.index.5u3d5tn59n00'), this.$t('user.list.index.5u3d5tn59o00')],
      columnData: ['ID', this.$t('user.list.index.5u3d5tn590s0'), this.$t('user.list.index.5u3d5tn59240'), this.$t('user.list.index.5u3d5tn59j40'), this.$t('user.list.index.5u3d5tn59k40'), this.$t('user.list.index.5u3d5tn59lk0'), this.$t('user.list.index.5u3d5tn593c0'), this.$t('user.list.index.5u3d5tn59n00'), this.$t('user.list.index.5u3d5tn59o00')],
      isIndeterminate: true
    };
  },
  created: function created() {
    // 浏览器高度
    var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

    // 此处减去100即为当前屏幕内除了表格高度以外其它内容的总高度，
    // this.tableHeight = windowHeight - 388;
  },
  activated: function activated() {
    this.userFrom.nikename = '';
    if ((0, _permission.checkPermi)(['merchant:user:page:list'])) this.getList(1);
  },
  mounted: function mounted() {
    var _this2 = this;
    if ((0, _permission.checkPermi)(['merchant:user:page:list'])) this.getList();
    this.$nextTick(function () {
      var tableHeader = _this2.$refs.tableheader.offsetHeight;
      _this2.tableHeight = _this2.$selfUtil.getTableHeight(tableHeader + 100);
    });
  },
  methods: {
    checkPermi: _permission.checkPermi,
    filterRegisterType: function filterRegisterType(status) {
      var statusMap = {
        wechat: '#FD5ACC',
        routine: '#A277FF',
        h5: '#E8B600',
        iosWx: '#1BBE6B',
        androidWx: '#EF9C20',
        ios: '#1890FF'
      };
      return statusMap[status];
    },
    onCollapse: function onCollapse() {
      var _this3 = this;
      this.collapse = !this.collapse;
      this.$nextTick(function () {
        var tableHeader = _this3.$refs.tableheader.offsetHeight;
        _this3.tableHeight = _this3.$selfUtil.getTableHeight(tableHeader + 150);
      });
    },
    getTemplateRow: function getTemplateRow(row) {
      this.formExtension.image = row.avatar;
      this.formExtension.spreadUid = row.uid;
    },
    // setExtension(row){
    //   this.formExtension = {
    //       image: '',
    //       spreadUid: '',
    //       userId: row.uid
    //   };
    //   this.extensionVisible = true
    // },
    handleCloseExtension: function handleCloseExtension() {
      this.extensionVisible = false;
    },
    modalPicTap: function modalPicTap() {
      this.userVisible = true;
    },
    resetForm: function resetForm() {
      this.visible = false;
    },
    reset: function reset(formName) {
      this.userFrom = {
        userType: '',
        sex: '',
        phone: '',
        dateLimit: '',
        nikename: '',
        page: 1,
        limit: 15
      };
      this.nikename = '';
      this.levelData = [];
      this.groupData = [];
      this.labelData = [];
      this.timeVal = [];
      this.getList();
    },
    // 发送文章
    sendNews: function sendNews() {
      if (this.selectionList.length === 0) return this.$message.warning(this.$t('user.list.index.5u3d5tn59p80'));
      var _this = this;
      this.$modalArticle(function (row) {}, 'send');
    },
    // 发送优惠劵
    onSend: function onSend() {
      if (this.selectionList.length === 0) return this.$message.warning(this.$t('user.list.index.5u3d5tn59q80'));
      var _this = this;
      this.$modalCoupon('send', this.keyNum += 1, [], function (row) {
        _this.formValidate.give_coupon_ids = [];
        _this.couponData = [];
        row.map(function (item) {
          _this.formValidate.give_coupon_ids.push(item.coupon_id);
          _this.couponData.push(item.title);
        });
        _this.selectionList = [];
      }, this.userIds, 'user');
    },
    Close: function Close() {
      this.Visible = false;
      this.levelVisible = false;
    },
    // 账户详情
    onDetails: function onDetails(id) {
      this.$refs.userDetailFrom.getUserDetail(id);
      this.$refs.userDetailFrom.dialogUserDetail = true;
    },
    // 等级
    // onLevel(id,level){
    //   var userLevel = new Object();
    //   this.levelList.forEach(item=>{
    //     if(item.id == level){
    //       userLevel.gradeLevel = item.grade;
    //     }
    //   })
    //   userLevel.uid = id;
    //   userLevel.level = level;
    //   this.levelInfo = userLevel;
    //   this.levelVisible = true;
    // },
    // 积分余额
    editPoint: function editPoint(id) {
      this.uid = id;
      this.VisiblePoint = true;
    },
    // 积分余额
    submitPointForm: (0, _validate.Debounce)(function (formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.PointValidateForm.uid = _this4.uid;
          _this4.loadingBtn = true;
          foundsApi(_this4.PointValidateForm).then(function (res) {
            _this4.$message.success(_this4.$t('user.list.index.5u3d5tn59sg0'));
            _this4.loadingBtn = false;
            _this4.handlePointClose();
            _this4.getList();
          }).catch(function () {
            _this4.loadingBtn = false;
          });
        } else {
          return false;
        }
      });
    }),
    // 积分余额
    handlePointClose: function handlePointClose() {
      this.VisiblePoint = false;
      this.PointValidateForm = {
        integralType: 2,
        integralValue: 0,
        moneyType: 2,
        moneyValue: 0,
        uid: ''
      };
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.$refs['dynamicValidateForm'].resetFields();
    },
    // 搜索
    userSearchs: function userSearchs() {
      this.userFrom.page = 1;
      this.getList();
    },
    // 选择国家
    changeCountry: function changeCountry() {
      if (this.userFrom.country === 'OTHER' || !this.userFrom.country) {
        this.selectedData = [];
        this.userFrom.province = '';
        this.userFrom.city = '';
        this.address = [];
      }
    },
    // 选择地址
    handleChange: function handleChange(value) {
      this.userFrom.province = value[0];
      this.userFrom.city = value[1];
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.userFrom.dateLimit = e ? this.timeVal.join(',') : '';
    },
    // 列表
    getList: function getList(num) {
      var _this5 = this;
      this.listLoading = true;
      this.userFrom.page = num ? num : this.userFrom.page;
      this.userFrom.nikename = encodeURIComponent(this.nikename);
      // this.userFrom.level = this.levelData.join(',')
      this.userFrom.groupId = this.groupData.join(',');
      this.userFrom.labelId = this.labelData.join(',');
      (0, _user.userListApi)(this.userFrom).then(function (res) {
        _this5.tableData.data = res.list;
        _this5.tableData.total = res.total;
        _this5.listLoading = false;
      }).catch(function () {
        _this5.listLoading = false;
      });
      this.checkedCities = this.$cache.local.has('user_stroge') ? this.$cache.local.getJSON('user_stroge') : this.checkedCities;
      this.$set(this, 'card_select_show', false);
    },
    pageChange: function pageChange(page) {
      var _this6 = this;
      this.$selfUtil.changePageCoreRecordData(this.multipleSelectionAll, this.multipleSelection, this.tableData.data, function (e) {
        _this6.multipleSelectionAll = e;
      });
      this.userFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this7 = this;
      this.$selfUtil.changePageCoreRecordData(this.multipleSelectionAll, this.multipleSelection, this.tableData.data, function (e) {
        _this7.multipleSelectionAll = e;
      });
      this.userFrom.limit = val;
      this.getList();
    },
    renderHeader: function renderHeader(h) {
      return h("p", [h("span", {
        "style": "padding-right:5px;"
      }, [this.$t('user.list.index.5u3d5tn595w0')])]);
      //<i class="el-icon-setting" onClick={()=>this.handleAddItem()}></i>
    },
    handleAddItem: function handleAddItem() {
      if (this.card_select_show) {
        this.$set(this, 'card_select_show', false);
      } else if (!this.card_select_show) {
        this.$set(this, 'card_select_show', true);
      }
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.checkedCities = val ? this.columnData : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.columnData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.columnData.length;
    },
    checkSave: function checkSave() {
      this.$modal.loading(this.$t('user.list.index.5u3d5tn59to0'));
      this.$cache.local.setJSON('user_stroge', this.checkedCities);
      setTimeout(this.$modal.closeLoading(), 1000);
    }
  }
};