var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mr10" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "formValidate",
          staticClass: "formValidate",
          attrs: {
            model: _vm.formValidate,
            "label-width": "130px",
            rules: _vm.rules,
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              !_vm.stockEdit ? _c("el-col", { attrs: { span: 24 } }) : _vm._e(),
              _vm._v(" "),
              _c("el-col", { staticClass: "mt10", attrs: { span: 24 } }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.stockEdit
            ? _c(
                "el-form-item",
                { attrs: { label: "商户商品分类：", prop: "cateIds" } },
                [
                  _c("el-cascader", {
                    staticClass: "selWidth",
                    attrs: {
                      options: _vm.merProductClassify,
                      props: _vm.props2,
                      clearable: "",
                      "show-all-levels": false,
                    },
                    model: {
                      value: _vm.formValidate.cateIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "cateIds", $$v)
                      },
                      expression: "formValidate.cateIds",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "labeltop", attrs: { label: "商品属性：" } },
            [
              _c(
                "el-table",
                {
                  staticClass: "tabNumWidth",
                  attrs: { data: _vm.ManyAttrValue, border: "", size: "mini" },
                },
                [
                  _vm.manyTabDate
                    ? _vm._l(_vm.manyTabDate, function (item, iii) {
                        return _c("el-table-column", {
                          key: iii,
                          attrs: {
                            align: "center",
                            label: _vm.manyTabTit[iii].title,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", {
                                      staticClass: "priceBox",
                                      domProps: {
                                        textContent: _vm._s(scope.row[iii]),
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "图片", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "upLoadPicBox" }, [
                              scope.row.image
                                ? _c("div", { staticClass: "pictrue tabPic" }, [
                                    _c("img", {
                                      attrs: { src: scope.row.image },
                                    }),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.attrValue, function (item, iii) {
                    return _c("el-table-column", {
                      key: iii,
                      attrs: {
                        label: _vm.formThead[iii].title,
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                (_vm.formThead[iii].title === "售价" ||
                                  _vm.formThead[iii].title === "库存") &&
                                !_vm.stockEdit
                                  ? _c("el-input", {
                                      staticClass: "priceBox",
                                      attrs: { type: "number", min: 0 },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          return _vm.keyupEvent(
                                            iii,
                                            scope.row[iii],
                                            scope.$index
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row[iii],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            iii,
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "scope.row[iii]",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row[iii])),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _vm._v(" "),
                  _vm.stockEdit
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "增加库存",
                          "min-width": "120",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    { staticClass: "all" },
                                    [
                                      _c("el-input", {
                                        staticClass: "priceBox",
                                        attrs: { type: "number", min: 0 },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            return _vm.keyupEvent(
                                              "stockAdd",
                                              scope.row.stockAdd,
                                              scope.$index
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row.stockAdd,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "stockAdd",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "scope.row.stockAdd",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          608472484
                        ),
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubmit("formValidate")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.loadingBtn ? "提交中 ..." : "确 定"))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }