"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.broadcastStatusFilter = broadcastStatusFilter;
exports.liveReviewStatusFilter = liveReviewStatusFilter;
exports.priceTypeFilter = priceTypeFilter;
exports.roomReviewStatusFilter = roomReviewStatusFilter;
exports.roomShowFilter = roomShowFilter;
/**
 * @description 直播商品審核狀態
 */
function liveReviewStatusFilter(status) {
  var statusMap = {
    0: '商户創建/撤回',
    1: '平台待審核/商户重新提交審核',
    2: '平台審核通過/微信審核中',
    3: '平台審核失敗',
    4: '微信審核成功',
    5: '微信審核失敗'
  };
  return statusMap[status];
}

/**
 * @description 直播狀態
 */
function broadcastStatusFilter(status) {
  var statusMap = {
    101: '直播中',
    102: '未開始',
    103: '已結束',
    104: '禁播',
    105: '暫停',
    106: '異常',
    107: '已過期'
  };
  return statusMap[status];
}

/**
 * @description 直播間審核狀態
 */
function roomReviewStatusFilter(status) {
  var statusMap = {
    0: '平台待審核',
    1: '平台審核失敗',
    2: '微信審核失敗',
    3: '微信審核成功'
  };
  return statusMap[status];
}

/**
 * @description 直播間開啓關閉狀態
 */
function roomShowFilter(status) {
  var statusMap = {
    0: '開啓',
    1: '關閉'
  };
  return statusMap[status];
}

/**
 * @description 直播商品價格類型
 */
function priceTypeFilter(status) {
  var statusMap = {
    1: '一口價',
    2: '價格區間',
    3: '折扣價'
  };
  return statusMap[status];
}