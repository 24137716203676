var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.dialogVisible,
            direction: _vm.direction,
            size: "1200px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "demo-drawer_title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _vm._v(
                _vm._s(_vm.$t("components.orderDetail.index.5u3iuv8dt3g0"))
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "demo-drawer__content",
            },
            [
              _vm.refundInfo.refundOrderNo
                ? [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.orderDetail.index.5u3iuv8du9g0")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "acea-row" }, [
                      _c("div", { staticClass: "description-term" }, [
                        _c("span", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.refundInfo.type === 1,
                              expression: "refundInfo.type === 1",
                            },
                          ],
                          staticClass: "iconfont icon-shipinhao mr5",
                          staticStyle: { color: "#f6ae02" },
                        }),
                        _vm._v(
                          "退款單號：" +
                            _vm._s(_vm.refundInfo.refundOrderNo) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "description-term",
                          staticStyle: { color: "red" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "components.orderDetail.index.5u3iuv8dugo0"
                                )
                              ) +
                              "\n            "
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("refundStatusFilter")(
                                  _vm.refundInfo.refundStatus
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "description-term fontColor3" },
                        [
                          _vm._v(
                            "退款金額：" + _vm._s(_vm.refundInfo.refundPrice)
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "description-term fontColor3" },
                        [
                          _vm._v(
                            "退款數量：" + _vm._s(_vm.refundInfo.applyRefundNum)
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "退款時間：" + _vm._s(_vm.refundInfo.refundTime)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "退款原因：" + _vm._s(_vm.refundInfo.refundReasonWap)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "退款説明：" +
                            _vm._s(
                              _vm._f("filterEmpty")(
                                _vm.refundInfo.refundReasonWapExplain
                              )
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.refundInfo.refundReason
                        ? _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "\n            拒絕退款説明：" +
                                _vm._s(
                                  _vm._f("filterEmpty")(
                                    _vm.refundInfo.refundReason
                                  )
                                ) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "description-term acea-row",
                          staticStyle: { width: "100%", display: "flex" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "components.orderDetail.index.5u3iuv8duko0"
                                )
                              ) +
                              "\n            "
                          ),
                          _c(
                            "div",
                            { staticClass: "demo-image__preview" },
                            [
                              _c("el-image", {
                                attrs: {
                                  src: _vm.refundInfo.image,
                                  "preview-src-list": [_vm.refundInfo.image],
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "ml20" }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.refundInfo.productName)),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.refundInfo.sku +
                                    "x" +
                                    _vm.refundInfo.payNum
                                )
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-divider"),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.orderDatalist
                ? _c(
                    "div",
                    { staticClass: "description" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.orderDetail.index.5u3iuv8duog0")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "acea-row" }, [
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "用户暱稱：" +
                              _vm._s(_vm.orderDatalist.nikeName) +
                              " | " +
                              _vm._s(_vm.orderDatalist.uid)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "用户電話：" + _vm._s(_vm.orderDatalist.phone)
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      parseFloat(_vm.orderDatalist.shippingType) === 1
                        ? [
                            _c("el-divider"),
                            _vm._v(" "),
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.orderDetail.index.5u3iuv8dusc0"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "acea-row" }, [
                              _c("div", { staticClass: "description-term" }, [
                                _vm._v(
                                  "收貨人：" +
                                    _vm._s(_vm.orderDatalist.realName)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "description-term" }, [
                                _vm._v(
                                  "收貨電話：" +
                                    _vm._s(_vm.orderDatalist.userPhone)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "description-term" }, [
                                _vm._v(
                                  "收貨地址：" +
                                    _vm._s(_vm.orderDatalist.userAddress)
                                ),
                              ]),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-divider"),
                      _vm._v(" "),
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.orderDetail.index.5u3iuv8dv7w0")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            data: _vm.orderDatalist.orderDetailList,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "components.orderDetail.index.5u3iuv8dvio0"
                              ),
                              width: "80",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "demo-image__preview" },
                                        [
                                          _c("el-image", {
                                            attrs: {
                                              src: scope.row.image,
                                              "preview-src-list": [
                                                scope.row.image,
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1825039654
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t(
                                "components.orderDetail.index.5u3iuv8dvko0"
                              ),
                              prop: "productName",
                              "min-width": "200",
                              "show-overflow-tooltip": true,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "price",
                              label: _vm.$t(
                                "components.orderDetail.index.5u3iuv8dvn40"
                              ),
                              "min-width": "90",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "sku",
                              label: _vm.$t(
                                "components.orderDetail.index.5u3iuv8dvp40"
                              ),
                              "min-width": "90",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "payNum",
                              label: _vm.$t(
                                "components.orderDetail.index.5u3iuv8dvqw0"
                              ),
                              "min-width": "90",
                              align: "center",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                      _vm._v(" "),
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.orderDetail.index.5u3iuv8dvsg0")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "acea-row" }, [
                        _c(
                          "div",
                          { staticClass: "description-term" },
                          [
                            _c(
                              "font",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.orderDatalist.type === 1,
                                    expression: "orderDatalist.type === 1",
                                  },
                                ],
                                attrs: { color: "#f6ae02" },
                              },
                              [_vm._v("[視頻號]")]
                            ),
                            _vm._v(" "),
                            _c(
                              "font",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.orderDatalist.type === 2,
                                    expression: "orderDatalist.type === 2",
                                  },
                                ],
                                attrs: { color: "#1890ff" },
                              },
                              [_vm._v("[秒殺]")]
                            ),
                            _vm._v(
                              "\n            訂單號：" +
                                _vm._s(_vm.orderDatalist.orderNo) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "description-term",
                            staticStyle: { color: "red" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "components.orderDetail.index.5u3iuv8dvvk0"
                                  )
                                ) +
                                "\n            "
                            ),
                            _vm.orderDatalist.refundStatus === 3
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.orderDetail.index.5u3iuv8dvx40"
                                      )
                                    )
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("orderStatusFilter")(
                                        _vm.orderDatalist.status
                                      )
                                    )
                                  ),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "支付狀態：" +
                              _vm._s(
                                _vm.orderDatalist.paid ? "已支付" : "未支付"
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "商品總數：" + _vm._s(_vm.orderDatalist.totalNum)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "商品總價：" +
                              _vm._s(_vm.orderDatalist.proTotalPrice)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "支付郵費：" + _vm._s(_vm.orderDatalist.payPostage)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "平台優惠金額：" +
                              _vm._s(_vm.orderDatalist.platCouponPrice)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "積分抵扣金額：" +
                              _vm._s(_vm.orderDatalist.integralPrice)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "實際支付：" + _vm._s(_vm.orderDatalist.payPrice)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "支付方式：" +
                              _vm._s(
                                _vm._f("payTypeFilter")(
                                  _vm.orderDatalist.payType
                                )
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "\n            配送方式：" +
                              _vm._s(_vm.orderDatalist.shippingTypeName) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "創建時間：" + _vm._s(_vm.orderDatalist.createTime)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "支付時間：" + _vm._s(_vm.orderDatalist.payTime)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "用户備註：" +
                              _vm._s(
                                _vm._f("filterEmpty")(
                                  _vm.orderDatalist.userRemark
                                )
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "商家備註：" +
                              _vm._s(
                                _vm._f("filterEmpty")(
                                  _vm.orderDatalist.merchantRemark
                                )
                              )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      parseFloat(_vm.orderDatalist.shippingType) === 1 &&
                      _vm.InvoiceList.length
                        ? [
                            _c("el-divider"),
                            _vm._v(" "),
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.orderDetail.index.5u3iuv8dvyk0"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-collapse",
                              {
                                staticClass: "InvoiceList",
                                model: {
                                  value: _vm.activeNames,
                                  callback: function ($$v) {
                                    _vm.activeNames = $$v
                                  },
                                  expression: "activeNames",
                                },
                              },
                              [
                                _vm._l(_vm.InvoiceList, function (item) {
                                  return [
                                    _c(
                                      "el-collapse-item",
                                      {
                                        attrs: {
                                          title:
                                            item.expressName +
                                            "：" +
                                            item.trackingNumber,
                                          name: item.id,
                                        },
                                      },
                                      [
                                        _c("template", { slot: "title" }, [
                                          _c(
                                            "div",
                                            { staticClass: "acea-row" },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.expressName +
                                                      "：" +
                                                      item.trackingNumber
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "hasPermi",
                                                      rawName: "v-hasPermi",
                                                      value: [
                                                        "merchant:order:logistics:info",
                                                      ],
                                                      expression:
                                                        "['merchant:order:logistics:info']",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    "margin-left": "5px",
                                                    "line-height": "1",
                                                    height: "auto",
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.openLogistics(
                                                        item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "物流查詢\n                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "acea-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "wrapper-pro acea-row",
                                            },
                                            _vm._l(
                                              item.detailList,
                                              function (j) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: j.id,
                                                    staticClass: "wrapper-img",
                                                  },
                                                  [
                                                    _c("el-image", {
                                                      attrs: { src: j.image },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "wrapper-num" },
                                          [
                                            _vm._v(
                                              "共" +
                                                _vm._s(item.totalNum) +
                                                "件商品"
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      parseFloat(_vm.orderDatalist.shippingType) === 2
                        ? [
                            _c("el-divider"),
                            _vm._v(" "),
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.orderDetail.index.5u3iuv8dw1c0"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "acea-row" }, [
                              _c("div", { staticClass: "description-term" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.orderDetail.index.5u3iuv8dw2s0"
                                      )
                                    ) +
                                    " ：" +
                                    _vm._s(_vm.orderDatalist.clerkName) +
                                    " |\n              " +
                                    _vm._s(_vm.orderDatalist.clerkId) +
                                    "\n            "
                                ),
                              ]),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.orderDatalist
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("components.orderDetail.index.5u3iuv8dw4c0"),
                visible: _vm.modal2,
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.modal2 = $event
                },
              },
            },
            [
              _c("div", { staticClass: "logistics acea-row row-top" }, [
                _c("div", { staticClass: "logistics_img" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/imgs/expressi.jpg") },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "logistics_cent" }, [
                  _c("span", { staticClass: "mb10" }, [
                    _vm._v("物流公司：" + _vm._s(_vm.resultInfo.expName)),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("物流單號：" + _vm._s(_vm.resultInfo.number)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.resultInfo.courierPhone,
                          expression: "resultInfo.courierPhone",
                        },
                      ],
                    },
                    [_vm._v("快遞站：" + _vm._s(_vm.resultInfo.courierPhone))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.resultInfo.courierPhone,
                          expression: "resultInfo.courierPhone",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        "快遞員電話：" + _vm._s(_vm.resultInfo.courierPhone)
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "acea-row row-column-around trees-coadd" },
                [
                  _c(
                    "div",
                    { staticClass: "scollhide" },
                    [
                      _c(
                        "el-timeline",
                        { attrs: { reverse: _vm.reverse } },
                        _vm._l(_vm.result, function (item, i) {
                          return _c("el-timeline-item", { key: i }, [
                            _c("p", {
                              staticClass: "time",
                              domProps: { textContent: _vm._s(item.time) },
                            }),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "content",
                              domProps: { textContent: _vm._s(item.status) },
                            }),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.modal2 = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.orderDetail.index.5u3iuv8dw640")
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }