"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = modalAttr;
/**
 * 商品规格弹窗形式组件
 * @param val 值
 * @param callback 回调函数
 * @param keyNum 重置组件key值
 * @returns {Promise<any>}
 */
function modalAttr(val, callback, keyNum) {
  var _this = this;
  var h = this.$createElement;
  return new Promise(function (resolve, reject) {
    _this.$msgbox({
      title: '属性规格',
      customClass: 'upload-form',
      closeOnClickModal: false,
      showClose: false,
      message: h('div', {
        class: 'common-form-upload'
      }, [h('attrFrom', {
        props: {
          currentRow: val,
          keyNum: keyNum
        },
        on: {
          getList: function getList() {
            callback();
          }
        }
      })]),
      showCancelButton: false,
      showConfirmButton: false
    }).then(function () {
      resolve();
    }).catch(function () {
      reject();
      _this.$message({
        type: 'info',
        message: '已取消'
      });
    });
  });
}