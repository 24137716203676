"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _modalAttr = _interopRequireDefault(require("@/libs/modal-attr"));
var _modalParserFrom = _interopRequireDefault(require("@/libs/modal-parserFrom"));
var _modalSure = _interopRequireDefault(require("@/libs/modal-sure"));
var _modalPrompt = _interopRequireDefault(require("@/libs/modal-prompt"));
var _timeOptions = _interopRequireDefault(require("@/libs/timeOptions"));
var constants = _interopRequireWildcard(require("@/utils/constants.js"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
var _asyncValidator = _interopRequireDefault(require("async-validator"));
var _parsing = require("@/utils/parsing");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = exports.default = {
  install: function install(Vue) {
    Vue.prototype.$modalSure = _modalSure.default;
    Vue.prototype.$modalAttr = _modalAttr.default;
    Vue.prototype.$modalParserFrom = _modalParserFrom.default;
    Vue.prototype.$timeOptions = _timeOptions.default;
    Vue.prototype.$constants = constants;
    Vue.prototype.$selfUtil = selfUtil;
    Vue.prototype.handleTree = _parsing.handleTree;
    Vue.prototype.parseTime = _parsing.parseTime;
    Vue.prototype.resetForm = _parsing.resetForm;
    Vue.prototype.$modalPrompt = _modalPrompt.default;
    Vue.prototype.$validator = function (rule) {
      return new _asyncValidator.default(rule);
    };
  }
};