"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.catListApi = catListApi;
exports.couponUserListApi = couponUserListApi;
exports.draftBrandlistApi = draftBrandlistApi;
exports.draftDelApi = draftDelApi;
exports.draftInfoApi = draftInfoApi;
exports.draftListApi = draftListApi;
exports.draftReviewApi = draftReviewApi;
exports.draftUpdateApi = draftUpdateApi;
exports.groupActivityListApi = groupActivityListApi;
exports.groupProAddApi = groupProAddApi;
exports.groupProDelApi = groupProDelApi;
exports.groupProDownApi = groupProDownApi;
exports.groupProListApi = groupProListApi;
exports.groupProSetPriceApi = groupProSetPriceApi;
exports.groupProUpApi = groupProUpApi;
exports.groupProWithdrawApi = groupProWithdrawApi;
exports.liveAssistantDelApi = liveAssistantDelApi;
exports.liveAssistantInfoApi = liveAssistantInfoApi;
exports.liveAssistantListApi = liveAssistantListApi;
exports.liveAssistantSaveApi = liveAssistantSaveApi;
exports.liveAssistantUpdateApi = liveAssistantUpdateApi;
exports.liveGoodsAuditApi = liveGoodsAuditApi;
exports.liveGoodsAuditResetApi = liveGoodsAuditResetApi;
exports.liveGoodsDelApi = liveGoodsDelApi;
exports.liveGoodsInfoApi = liveGoodsInfoApi;
exports.liveGoodsListApi = liveGoodsListApi;
exports.liveGoodsSaveApi = liveGoodsSaveApi;
exports.liveGoodsSortApi = liveGoodsSortApi;
exports.liveGoodsUpdateApi = liveGoodsUpdateApi;
exports.liveGoodsuUpdateApi = liveGoodsuUpdateApi;
exports.liveMediaUploadlocalApi = liveMediaUploadlocalApi;
exports.liveRoomAddasstoroomeApi = liveRoomAddasstoroomeApi;
exports.liveRoomAddgoodsApi = liveRoomAddgoodsApi;
exports.liveRoomAddsubanchorApi = liveRoomAddsubanchorApi;
exports.liveRoomCreateApi = liveRoomCreateApi;
exports.liveRoomDeleteApi = liveRoomDeleteApi;
exports.liveRoomDeletegoodsinroomApi = liveRoomDeletegoodsinroomApi;
exports.liveRoomDeletesubanchorApi = liveRoomDeletesubanchorApi;
exports.liveRoomEditApi = liveRoomEditApi;
exports.liveRoomEditasstoroomeApi = liveRoomEditasstoroomeApi;
exports.liveRoomGetassApi = liveRoomGetassApi;
exports.liveRoomGetsharecodeApi = liveRoomGetsharecodeApi;
exports.liveRoomGetsubanchorApi = liveRoomGetsubanchorApi;
exports.liveRoomGetvideoApi = liveRoomGetvideoApi;
exports.liveRoomGoodslistApi = liveRoomGoodslistApi;
exports.liveRoomGoodsonsaleApi = liveRoomGoodsonsaleApi;
exports.liveRoomGoodsortApi = liveRoomGoodsortApi;
exports.liveRoomInfoApi = liveRoomInfoApi;
exports.liveRoomIsFeedsPublicApi = liveRoomIsFeedsPublicApi;
exports.liveRoomListApi = liveRoomListApi;
exports.liveRoomMangerassApi = liveRoomMangerassApi;
exports.liveRoomModifyassApi = liveRoomModifyassApi;
exports.liveRoomModifyassUpdateApi = liveRoomModifyassUpdateApi;
exports.liveRoomModifysubanchorApi = liveRoomModifysubanchorApi;
exports.liveRoomPushgoodsApi = liveRoomPushgoodsApi;
exports.liveRoomRemoveassApi = liveRoomRemoveassApi;
exports.liveRoomRemoveasstoroomeApi = liveRoomRemoveasstoroomeApi;
exports.liveRoomUpdateclosekfApi = liveRoomUpdateclosekfApi;
exports.liveRoomUpdatecommentApi = liveRoomUpdatecommentApi;
exports.liveRoomUpdatereplayApi = liveRoomUpdatereplayApi;
exports.mediaUploadApi = mediaUploadApi;
exports.payProductDeleteApi = payProductDeleteApi;
exports.payProductGetApi = payProductGetApi;
exports.seckillActivityDetailApi = seckillActivityDetailApi;
exports.seckillActivityListApi = seckillActivityListApi;
exports.seckillProAddApi = seckillProAddApi;
exports.seckillProDelApi = seckillProDelApi;
exports.seckillProDownApi = seckillProDownApi;
exports.seckillProListApi = seckillProListApi;
exports.seckillProSetPriceApi = seckillProSetPriceApi;
exports.seckillProUpApi = seckillProUpApi;
exports.seckillProWithdrawApi = seckillProWithdrawApi;
exports.shopImgUploadApi = shopImgUploadApi;
exports.videoAddApi = videoAddApi;
exports.videoDownApi = videoDownApi;
exports.videoProductListApi = videoProductListApi;
exports.videoUpApi = videoUpApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 会员领取记录 列表
 * @param pram
 */
function couponUserListApi(params) {
  return (0, _request.default)({
    url: '/admin/merchant/coupon/user/list',
    method: 'get',
    params: params
  });
}

/**
 * 视频号 草稿列表
 */
function draftListApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/pay/component/draftproduct/draft/list",
    method: 'get',
    params: params
  });
}

/**
 * 视频号 过审商品列表
 */
function videoProductListApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/pay/component/product/list",
    method: 'get',
    params: params
  });
}

/**
 * 视频号 类目
 */
function catListApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/pay/component/cat/get/list",
    method: 'get'
  });
}

/**
 * 视频号 草稿商品添加
 */
function videoAddApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/pay/component/draftproduct/add",
    method: 'post',
    data: data
  });
}

/**
 * 视频号 过审商品上架
 */
function videoUpApi(proId) {
  return (0, _request.default)({
    url: "/admin/merchant/pay/component/product/puton/".concat(proId),
    method: 'post'
  });
}

/**
 * 视频号 过审商品下架
 */
function videoDownApi(proId) {
  return (0, _request.default)({
    url: "/admin/merchant/pay/component/product/putdown/".concat(proId),
    method: 'post'
  });
}

/**
 * 视频号 草稿商品删除
 */
function draftDelApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/pay/component/draftproduct/delete/".concat(id),
    method: 'get'
  });
}

/**
 * 视频号 草稿商品详情
 */
function draftInfoApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/pay/component/draftproduct/draft/get/".concat(id),
    method: 'get'
  });
}

/**
 * 视频号 草稿商品编辑
 */
function draftUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/pay/component/draftproduct/update",
    method: 'post',
    data: data
  });
}

/**
 * 视频号 草稿商品图片上传至微信
 */
function shopImgUploadApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/pay/component/shop/img/upload",
    method: 'post',
    data: data
  });
}

/**
 * 视频号 商家审核草稿商品
 */
function draftReviewApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/pay/component/draftproduct/review",
    method: 'post',
    data: data
  });
}

/**
 * 视频号 品牌列表
 */
function draftBrandlistApi() {
  return (0, _request.default)({
    url: "/admin/merchant/pay/component/shop/brand/usable/list",
    method: 'get'
  });
}

/**
 * 视频号 过审商品删除
 */
function payProductDeleteApi(proId) {
  return (0, _request.default)({
    url: "/admin/merchant/pay/component/product/delete/".concat(proId),
    method: 'get'
  });
}

/**
 * 视频号 过审商品详情
 */
function payProductGetApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/pay/component/product/get/".concat(id),
    method: 'get'
  });
}

/**
 * 拼团 拼团活动分页列表
 */
function groupActivityListApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/group/activity/page",
    method: 'get',
    params: params
  });
}

/**
 * 拼团 拼团商品列表
 */
function groupProListApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/group/product/page",
    method: 'get',
    params: params
  });
}

/**
 * 拼团 拼团商品删除
 */
function groupProDelApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/group/product/delete",
    method: 'POST',
    data: data
  });
}

/**
 * 拼团 拼团商品添加
 */
function groupProAddApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/group/product/add",
    method: 'POST',
    data: data
  });
}

/**
 * 拼团 拼团商品下架
 */
function groupProDownApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/group/product/down",
    method: 'post',
    data: data
  });
}

/**
 * 拼团 拼团商品上架
 */
function groupProUpApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/group/product/up",
    method: 'post',
    data: data
  });
}

/**
 * 拼团 拼团商品设置活动价
 */
function groupProSetPriceApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/group/product/set/price",
    method: 'post',
    data: data
  });
}

/**
 * 拼团 拼团商品撤回审核
 */
function groupProWithdrawApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/group/product/withdraw/".concat(id),
    method: 'post'
  });
}

/**
 * 秒杀 秒杀活动分页列表
 */
function seckillActivityListApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/seckill/activity/page",
    method: 'get',
    params: params
  });
}

/**
 * 秒杀 秒杀商品列表
 */
function seckillProListApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/seckill/product/page",
    method: 'get',
    params: params
  });
}

/**
 * 秒杀 秒杀活动详情
 */
function seckillActivityDetailApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/seckill/activity/detail/".concat(id),
    method: 'get'
  });
}

/**
 * 秒杀 秒杀商品删除
 */
function seckillProDelApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/seckill/product/delete",
    method: 'POST',
    data: data
  });
}

/**
 * 秒杀 秒杀商品添加
 */
function seckillProAddApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/seckill/product/add",
    method: 'POST',
    data: data
  });
}

/**
 * 秒杀 秒杀商品下架
 */
function seckillProDownApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/seckill/product/down",
    method: 'post',
    data: data
  });
}

/**
 * 秒杀 秒杀商品上架
 */
function seckillProUpApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/seckill/product/up",
    method: 'post',
    data: data
  });
}

/**
 * 秒杀 秒杀商品设置活动价
 */
function seckillProSetPriceApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/seckill/product/set/price",
    method: 'post',
    data: data
  });
}

/**
 * 秒杀 秒杀商品撤回审核
 */
function seckillProWithdrawApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/seckill/product/withdraw/".concat(id),
    method: 'post'
  });
}

/**
 * 直播 商品撤回审核
 */
function liveGoodsAuditResetApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/goods/audit/reset/".concat(id),
    method: 'get'
  });
}

/**
 * 直播 重新提交申请
 */
function liveGoodsAuditApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/goods/audit/".concat(id),
    method: 'get'
  });
}

/**
 * 直播 商品 删除
 */
function liveGoodsDelApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/goods/delete/".concat(id),
    method: 'get'
  });
}

/**
 * 直播 商品 详情
 */
function liveGoodsInfoApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/goods/info/".concat(id),
    method: 'get'
  });
}

/**
 * 直播 商品 分页列表
 */
function liveGoodsListApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/goods/list",
    method: 'get',
    params: params
  });
}

/**
 * 直播 商品 新增并提审
 */
function liveGoodsSaveApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/goods/save",
    method: 'POST',
    data: data
  });
}

/**
 * 直播 商品 修改
 */
function liveGoodsuUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/goods/update",
    method: 'POST',
    data: data
  });
}

/**
 * 直播 商品 微信 - 素材上传
 */
function mediaUploadApi(data, params) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/media/upload",
    method: 'POST',
    params: params,
    data: data
  });
}

/**
 * 直播 商品 修改
 */
function liveGoodsUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/goods/update",
    method: 'POST',
    data: data
  });
}

/**
 * 直播 小助手 删除
 */
function liveAssistantDelApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/assistant/delete/".concat(id),
    method: 'get'
  });
}

/**
 * 直播 小助手 详情
 */
function liveAssistantInfoApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/assistant/info/".concat(id),
    method: 'get'
  });
}

/**
 * 直播 小助手 分页列表
 */
function liveAssistantListApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/assistant/list",
    method: 'get',
    params: params
  });
}

/**
 * 直播 小助手 新增
 */
function liveAssistantSaveApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/assistant/save",
    method: 'POST',
    data: data
  });
}

/**
 * 直播 小助手 修改
 */
function liveAssistantUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/assistant/update",
    method: 'POST',
    data: data
  });
}

/**
 * 直播 直播间 新增直播间小助手 到直播间
 */
function liveRoomAddasstoroomeApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/addasstoroome",
    method: 'POST',
    data: data
  });
}

/**
 * 直播 直播间 新增直播间小助手 到直播间
 */
function liveRoomEditasstoroomeApi(id, assid) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/modifyass/".concat(id, "/").concat(assid),
    method: 'POST'
  });
}

/**
 * 直播 直播间  删除直播间小助手
 */
function liveRoomRemoveasstoroomeApi(id, assid) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/removeass/".concat(id, "/").concat(assid),
    method: 'get'
  });
}

/**
 * 直播 直播间 导入商品
 */
function liveRoomAddgoodsApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/addgoods",
    method: 'POST',
    data: data
  });
}

/**
 * 直播 直播间 新增直播间主播副号
 */
function liveRoomAddsubanchorApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/addsubanchor",
    method: 'POST',
    data: data
  });
}

/**
 * 直播 直播间 创建直播室
 */
function liveRoomCreateApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/create",
    method: 'POST',
    data: data
  });
}

/**
 * 直播 直播间 删除直播室
 */
function liveRoomDeleteApi(roomId) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/delete/".concat(roomId),
    method: 'GET'
  });
}

/**
 * 直播 直播间 删除直播间商品
 */
function liveRoomDeletegoodsinroomApi(roomId, goodsId) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/deletegoodsinroom/".concat(roomId, "/").concat(goodsId),
    method: 'GET'
  });
}

/**
 * 直播 直播间 删除直播间主播副号
 */
function liveRoomDeletesubanchorApi(roomId) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/deletesubanchor/".concat(roomId),
    method: 'GET'
  });
}

/**
 * 直播 直播间 编辑直播室
 */
function liveRoomEditApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/edit",
    method: 'POST',
    data: data
  });
}

/**
 * 直播 直播间 获取直播间分享二维码
 */
function liveRoomGetsharecodeApi(roomId) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/getsharecode/".concat(roomId),
    method: 'get'
  });
}

/**
 * 直播 直播间 获取直播间主播副号
 */
function liveRoomGetsubanchorApi(roomId) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/getsubanchor/".concat(roomId),
    method: 'get'
  });
}

/**
 * 直播 直播间 下载讲解视频
 */
function liveRoomGetvideoApi(id, roomId) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/getvideo/".concat(id, "/").concat(goodsId),
    method: 'get'
  });
}

/**
 * 直播 直播间 商品上下架
 */
function liveRoomGoodsonsaleApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/goodsonsale",
    method: 'post',
    data: data
  });
}

/**
 * 直播 直播间 商品排序
 */
function liveRoomGoodsortApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/goodsort",
    method: 'post',
    data: data
  });
}

/**
 * 直播 直播间 查询直播间小助手
 */
function liveRoomModifyassApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/modifyass/".concat(id),
    method: 'get'
  });
}

/**
 * 直播 直播间 修改直播间小助手
 */
function liveRoomModifyassUpdateApi(id, assid) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/modifyass/".concat(id, "/").concat(assid),
    method: 'get'
  });
}

/**
 * 直播 直播间 修改直播间主播副号
 */
function liveRoomModifysubanchorApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/modifysubanchor",
    method: 'post',
    data: data
  });
}

/**
 * 直播 直播间 推送商品
 */
function liveRoomPushgoodsApi(roomId, goodsId) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/pushgoods/".concat(roomId, "/").concat(goodsId),
    method: 'get'
  });
}

/**
 * 直播 直播间 删除直播间小助手
 */
function liveRoomRemoveassApi(id, assid) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/removeass/".concat(id, "/").concat(assid),
    method: 'get'
  });
}

/**
 * 直播 直播间 禁言管理
 */
function liveRoomUpdatecommentApi(id, banComment) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/updatecomment/".concat(id, "/").concat(banComment),
    method: 'get'
  });
}

/**
 * 直播 直播间 官方收录管理
 */
function liveRoomIsFeedsPublicApi(id, isFeedsPublic) {
  console.log(id, isFeedsPublic);
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/updatefeedpublic/".concat(id, "/").concat(isFeedsPublic),
    method: 'get'
  });
}

/**
 * 直播 直播间 开启回放
 */
function liveRoomUpdatereplayApi(id, closeReplay) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/updatereplay/".concat(id, "/").concat(closeReplay),
    method: 'get'
  });
}

/**
 * 直播 直播间 直播室列表和回放
 */
function liveRoomListApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/list",
    method: 'post',
    data: {
      pageParamRequest: {
        limit: data.limit,
        page: data.page
      },
      searchRequest: {
        reviewStatus: data.reviewStatus,
        liveStatus: data.liveStatus,
        keywords: data.keywords,
        storeShow: data.storeShow,
        star: data.star
      }
    }
  });
}

/**
 * 直播 直播间 素材上传本地
 */
function liveMediaUploadlocalApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/media/uploadlocal",
    method: 'post',
    data: data
  });
}

/**
 * 直播 直播间 直播间商品列表
 */
function liveRoomGoodslistApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/goodslist/".concat(id),
    method: 'get'
  });
}

/**
 * 直播 直播间 直播间商品列表
 */
function liveRoomInfoApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/info/".concat(id),
    method: 'get'
  });
}

/**
 * 直播 直播间 直播间商品列表
 */
function liveRoomGetassApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/getass/".concat(id),
    method: 'get'
  });
}

/**
 * 直播 直播间 管理直播间小助手
 */
function liveRoomMangerassApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/mangerass",
    method: 'post',
    data: data
  });
}

/**
 * 直播 直播间 客服管理
 */
function liveRoomUpdateclosekfApi(id, closeKf) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/room/updateclosekf/".concat(id, "/").concat(closeKf),
    method: 'get'
  });
}

/**
 * @description 直播-商品-修改排序
 */
function liveGoodsSortApi(id, sort) {
  return (0, _request.default)({
    url: "/admin/merchant/mp/live/goods/sort/".concat(id, "/").concat(sort),
    method: 'GET'
  });
}