"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
require("@/assets/js/canvas-nest.min.js");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Login',
  data: function data() {
    var _this2 = this;
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!(0, _validate.validUsername)(value)) {
        callback(new Error('Please enter the correct user name'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6 || value.length > 12) {
        callback(new Error(_this2.$t('login.index.5u3ik8qedbw0')));
      } else {
        callback();
      }
    };
    return {
      showCaptchatImg: false,
      captchatImg: '',
      leftLogo: '',
      loginLogo: '',
      backgroundImages: '',
      backgroundImageMo: require('@/assets/imgs/bg.jpg'),
      fullWidth: document.body.clientWidth,
      swiperOption: {
        pagination: {
          el: '.pagination'
        },
        autoplay: {
          enabled: true,
          disableOnInteraction: false,
          delay: 3000
        }
      },
      loginForm: {
        account: '',
        // admin
        pwd: '',
        key: '',
        code: '',
        captcha: {
          captchaVerification: '',
          secretKey: '',
          token: ''
        }
      },
      loginRules: {
        account: [{
          required: true,
          trigger: 'blur',
          message: this.$t('login.index.5u3ik8qenh80')
        }],
        // validator: validateUsername
        pwd: [{
          required: true,
          trigger: 'blur',
          message: this.$t('login.index.5u3ik8qedh80')
        }],
        code: [{
          required: true,
          message: this.$t('login.index.5u3ik8qedko0'),
          trigger: 'blur'
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      disabled: false
    };
  },
  components: {},
  watch: {
    fullWidth: function fullWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        var that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          that.timer = false;
        }, 400);
      }
    },
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    var _this = this;
    document.onkeydown = function (e) {
      if (_this.$route.path.indexOf('login') !== -1) {
        var key = window.event.keyCode;
        if (key === 13) {
          _this.handleLogin();
        }
      }
    };
    window.addEventListener('resize', this.handleResize);
  },
  mounted: function mounted() {
    var _this3 = this;
    this.getInfo();
    this.$nextTick(function () {
      if (_this3.screenWidth < 768) {
        document.getElementsByTagName('canvas')[0].removeAttribute('class', 'index_bg');
      } else {
        document.getElementsByTagName('canvas')[0].className = 'index_bg';
      }
    });
    if (this.loginForm.account === '') {
      this.$refs.account.focus();
    } else if (this.loginForm.pwd === '') {
      this.$refs.pwd.focus();
    }
    this.getCaptcha();
    // this.agentWeiXinLogin()
  },
  beforeCreate: function beforeCreate() {
    if (this.fullWidth < 768) {
      document.getElementsByTagName('canvas')[0].removeAttribute('class', 'index_bg');
    } else {
      document.getElementsByTagName('canvas')[0].className = 'index_bg';
    }
  },
  destroyed: function destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    document.getElementsByTagName('canvas')[0].removeAttribute('class', 'index_bg');
  },
  methods: {
    handleResize: function handleResize(event) {
      this.fullWidth = document.body.clientWidth;
      if (this.fullWidth < 768) {
        document.getElementsByTagName('canvas')[0].removeAttribute('class', 'index_bg');
      } else {
        document.getElementsByTagName('canvas')[0].className = 'index_bg';
      }
    },
    getInfo: function getInfo() {
      var _this4 = this;
      (0, _user.getLoginPicApi)().then(function (res) {
        _this4.leftLogo = res.leftLogo;
        _this4.loginLogo = res.loginLogo;
        _this4.backgroundImages = res.backgroundImage;
        // Cookies.set('MerInfo', JSON.stringify(data))
      });
    },
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this5 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this5.$refs.pwd.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this6 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this6.success(null);
        } else {
          return false;
        }
      });
    },
    success: function success(params) {
      var _this7 = this;
      this.loginForm.captcha = this.$store.state.user.captcha;
      var loading = this.$loading({
        lock: true,
        text: this.$t('login.index.5u3ik8qedpw0')
      });
      this.$store.dispatch('user/login', this.loginForm).then(function () {
        _this7.$store.commit('product/SET_AdminProductClassify', []);
        _this7.$store.commit('product/SET_MerProductClassify', []);
        _this7.$store.commit('merchant/SET_MerchantClassify', []);
        _this7.$store.commit('merchant/SET_MerchantType', []);
        _this7.$store.commit('product/SET_ShippingTemplates', []);
        _this7.$router.push({
          path: _this7.redirect || '/',
          query: _this7.otherQuery
        });
        loading.close();
        _this7.disabled = true;
      }).catch(function (err) {
        loading.close();
        _this7.disabled = false;
        _this7.getCaptcha();
      });
    },
    getCaptcha: function getCaptcha() {
      var _this8 = this;
      (0, _user.captchaApi)().then(function (data) {
        _this8.captchatImg = data.code;
        _this8.loginForm.key = data.key;
        _this8.showCaptchatImg = false;
        setTimeout(function () {
          _this8.showCaptchatImg = true;
        }, 270000);
      }).catch(function (_ref) {
        var message = _ref.message;
        _this8.$message.error(message);
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    }
  }
};