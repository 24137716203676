"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.merCreateTypeFilter = merCreateTypeFilter;
exports.selfTypeFilter = selfTypeFilter;
//商户過濾器

/**
 * 商户創建類型
 */
function merCreateTypeFilter(status) {
  var statusMap = {
    admin: '管理員創建',
    apply: '商户入駐申請'
  };
  return statusMap[status];
}

/**
 * 商户類別
 */
function selfTypeFilter(status) {
  var statusMap = {
    true: '自營',
    false: '非自營'
  };
  return statusMap[status];
}