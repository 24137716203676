"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBaseInfoApi = getBaseInfoApi;
exports.merchantCategoryAllListApi = merchantCategoryAllListApi;
exports.merchantConfigInfoApi = merchantConfigInfoApi;
exports.merchantSwitchApi = merchantSwitchApi;
exports.merchantTransferApi = merchantTransferApi;
exports.merchantTransferEditApi = merchantTransferEditApi;
exports.merchantTypeAllListApi = merchantTypeAllListApi;
exports.merchantUpdateApi = merchantUpdateApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 商户分类 全部列表
 */
function merchantCategoryAllListApi() {
  return (0, _request.default)({
    url: '/admin/merchant/category/all/list',
    method: 'get'
  });
}

/**
 * 店铺类型 全部列表
 */
function merchantTypeAllListApi() {
  return (0, _request.default)({
    url: '/admin/merchant/type/all/list',
    method: 'get'
  });
}

/**
 * @description 商户基本设置 -- 商户详情
 */
function getBaseInfoApi() {
  return (0, _request.default)({
    url: '/admin/merchant/base/info',
    method: 'get'
  });
}

/**
 * @description 商户基本设置 -- 提交
 */
function merchantUpdateApi(data) {
  return (0, _request.default)({
    url: '/admin/merchant/config/info/edit',
    method: 'post',
    data: data
  });
}

/**
 * @description 商户基本设置 -- 配置信息
 */
function merchantConfigInfoApi() {
  return (0, _request.default)({
    url: '/admin/merchant/config/info',
    method: 'get'
  });
}

/**
 * @description 商户基本设置 -- 商户开关
 */
function merchantSwitchApi() {
  return (0, _request.default)({
    url: '/admin/merchant/switch/update',
    method: 'post'
  });
}

/**
 * @description 商户基本设置 -- 转账信息
 */
function merchantTransferApi() {
  return (0, _request.default)({
    url: '/admin/merchant/settlement/info',
    method: 'GET'
  });
}

/**
 * @description 商户基本设置 -- 转账信息编辑
 */
function merchantTransferEditApi(data) {
  return (0, _request.default)({
    url: '/admin/merchant/settlement/info/edit',
    method: 'post',
    data: data
  });
}