"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'StoreAttr',
  data: function data() {
    return {
      formDynamic: {
        ruleName: '',
        ruleValue: []
      },
      tableFrom: {
        page: 1,
        limit: 20,
        keywords: ''
      },
      keywords: '',
      tableData: {
        data: [],
        loading: false,
        total: 0
      },
      listLoading: false,
      selectionList: [],
      multipleSelectionAll: [],
      idKey: 'id',
      nextPageFlag: false,
      keyNum: 0
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['merchant:product:rule:page:list'])) this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var _this2 = this;
      this.selectionList = val;
      setTimeout(function () {
        _this2.$selfUtil.changePageCoreRecordData(_this2.multipleSelectionAll, _this2.multipleSelection, _this2.tableData.data, function (e) {
          _this2.multipleSelectionAll = e;
        });
      }, 50);
    },
    // 设置选中的方法
    setSelectRow: function setSelectRow() {
      if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
        return;
      }
      // 标识当前行的唯一键的名称
      var idKey = this.idKey;
      var selectAllIds = [];
      this.multipleSelectionAll.forEach(function (row) {
        selectAllIds.push(row[idKey]);
      });
      this.$refs.table.clearSelection();
      for (var i = 0; i < this.tableData.data.length; i++) {
        if (selectAllIds.indexOf(this.tableData.data[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.table.toggleRowSelection(this.tableData.data[i], true);
        }
      }
    },
    add: function add() {
      var _this = this;
      this.$modalAttr(Object.assign({}, this.formDynamic), function () {
        _this.getList();
      }, this.keyNum += 1);
    },
    // 列表
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.keywords = encodeURIComponent(this.keywords);
      (0, _product.templateListApi)(this.tableFrom).then(function (res) {
        var list = res.list;
        _this3.tableData.data = list;
        _this3.tableData.total = res.total;
        for (var i = 0; i < list.length; i++) {
          list[i].ruleValue = JSON.parse(list[i].ruleValue);
        }
        _this3.$nextTick(function () {
          this.setSelectRow(); // 调用跨页选中方法
        });
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      var _this4 = this;
      this.$selfUtil.changePageCoreRecordData(this.multipleSelectionAll, this.multipleSelection, this.tableData.data, function (e) {
        _this4.multipleSelectionAll = e;
      });
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this5 = this;
      this.$selfUtil.changePageCoreRecordData(this.multipleSelectionAll, this.multipleSelection, this.tableData.data, function (e) {
        _this5.multipleSelectionAll = e;
      });
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this6 = this;
      this.$modalSure().then(function () {
        (0, _product.attrDeleteApi)(id).then(function () {
          _this6.$message.success(_this6.$t('product.attr.index.5u2ql775w380'));
          _this6.tableData.data.splice(idx, 1);
        });
      }).catch(function () {});
    },
    handleDeleteAll: function handleDeleteAll() {
      var _this7 = this;
      if (!this.multipleSelectionAll.length) return this.$message.warning(this.$t('product.attr.index.5u2ql775w640'));
      var data = [];
      this.multipleSelectionAll.map(function (item) {
        data.push(item.id);
      });
      this.ids = data.join(',');
      this.$modalSure().then(function () {
        (0, _product.attrDeleteApi)(_this7.ids).then(function () {
          _this7.$message.success(_this7.$t('product.attr.index.5u2ql775w380'));
          _this7.getList();
        });
      }).catch(function () {});
    },
    onEdit: function onEdit(val) {
      var _this = this;
      this.$modalAttr(JSON.parse(JSON.stringify(val)), function () {
        _this.getList();
      });
    }
  }
};