var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("coupon.list.creatCoupon.5u3io9xtaho0"),
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      maxlength: "20",
                      placeholder: _vm.$t(
                        "coupon.list.creatCoupon.5u3io9xtbk40"
                      ),
                      disabled: _vm.$route.params.edit ? true : false,
                    },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("coupon.list.creatCoupon.5u3io9xtbnw0"),
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.$route.params.edit ? true : false,
                      },
                      model: {
                        value: _vm.ruleForm.category,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "category", $$v)
                        },
                        expression: "ruleForm.category",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtbqc0"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtbss0"))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.category === 2
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("coupon.list.creatCoupon.5u3io9xtbwc0"),
                        prop: "checked",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "acea-row" },
                        [
                          _vm.ruleForm.checked.length
                            ? _vm._l(
                                _vm.ruleForm.checked,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "pictrue" },
                                    [
                                      _c("img", { attrs: { src: item.image } }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-error btndel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(index)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              on: { click: _vm.changeGood },
                            },
                            [
                              _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("coupon.list.creatCoupon.5u3io9xtby80"),
                    prop: "money",
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: {
                      min: 1,
                      max: 9999,
                      label: _vm.$t("coupon.list.creatCoupon.5u3io9xtby80"),
                      disabled: _vm.$route.params.edit ? true : false,
                    },
                    model: {
                      value: _vm.ruleForm.money,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "money",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.money",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("coupon.list.creatCoupon.5u3io9xtc100"),
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.$route.params.edit ? true : false,
                      },
                      model: {
                        value: _vm.threshold,
                        callback: function ($$v) {
                          _vm.threshold = $$v
                        },
                        expression: "threshold",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtc400"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtc780"))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.threshold
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("coupon.list.creatCoupon.5u3io9xtc9c0"),
                        prop: "minPrice",
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          step: 1,
                          "step-strictly": "",
                          min: 1,
                          label: _vm.$t("coupon.list.creatCoupon.5u3io9xtccw0"),
                          disabled: _vm.$route.params.edit ? true : false,
                        },
                        model: {
                          value: _vm.ruleForm.minPrice,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "minPrice",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.minPrice",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("coupon.list.creatCoupon.5u3io9xtcew0"),
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.$route.params.edit ? true : false,
                      },
                      model: {
                        value: _vm.ruleForm.isFixedTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isFixedTime", $$v)
                        },
                        expression: "ruleForm.isFixedTime",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtci00"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtckc0"))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.ruleForm.isFixedTime
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("coupon.list.creatCoupon.5u3io9xtcmg0"),
                        prop: "day",
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          min: 0,
                          max: 999,
                          label: _vm.$t("coupon.list.creatCoupon.5u3io9xtcmg0"),
                          disabled: _vm.$route.params.edit ? true : false,
                        },
                        model: {
                          value: _vm.ruleForm.day,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "day",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.day",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.isFixedTime
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("coupon.list.creatCoupon.5u3io9xtcoo0"),
                        prop: "resource",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "550px" },
                        attrs: {
                          disabled: _vm.$route.params.edit ? true : false,
                          type: "datetimerange",
                          "range-separator": _vm.$t(
                            "coupon.list.creatCoupon.5u3io9xtcrs0"
                          ),
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "start-placeholder": _vm.$t(
                            "coupon.list.creatCoupon.5u3io9xtcu80"
                          ),
                          "picker-options": _vm.pickerOptions,
                          "end-placeholder": _vm.$t(
                            "coupon.list.creatCoupon.5u3io9xtcx00"
                          ),
                        },
                        model: {
                          value: _vm.termTime,
                          callback: function ($$v) {
                            _vm.termTime = $$v
                          },
                          expression: "termTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("coupon.list.creatCoupon.5u3io9xtd2g0"),
                    prop: "isTimeReceive",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.$route.params.edit ? true : false,
                      },
                      model: {
                        value: _vm.ruleForm.isTimeReceive,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isTimeReceive", $$v)
                        },
                        expression: "ruleForm.isTimeReceive",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtd4g0"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtd6o0"))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.isTimeReceive
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("coupon.list.creatCoupon.5u3io9xtd8g0"),
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "550px" },
                        attrs: {
                          disabled: _vm.$route.params.edit ? true : false,
                          type: "datetimerange",
                          "range-separator": _vm.$t(
                            "coupon.list.creatCoupon.5u3io9xtcrs0"
                          ),
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "picker-options": _vm.pickerOptions,
                          "start-placeholder": _vm.$t(
                            "coupon.list.creatCoupon.5u3io9xtcu80"
                          ),
                          "end-placeholder": _vm.$t(
                            "coupon.list.creatCoupon.5u3io9xtcx00"
                          ),
                        },
                        on: { blur: _vm.handleTimestamp },
                        model: {
                          value: _vm.isForeverTime,
                          callback: function ($$v) {
                            _vm.isForeverTime = $$v
                          },
                          expression: "isForeverTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("coupon.list.creatCoupon.5u3io9xtdak0"),
                    prop: "receiveType",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.$route.params.edit ? true : false,
                      },
                      model: {
                        value: _vm.ruleForm.receiveType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "receiveType", $$v)
                        },
                        expression: "ruleForm.receiveType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtdc80"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 4 } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtde00"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtdfw0"))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("coupon.list.creatCoupon.5u3io9xtdhs0"),
                    prop: "isLimited",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.$route.params.edit ? true : false,
                      },
                      model: {
                        value: _vm.ruleForm.isLimited,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isLimited", $$v)
                        },
                        expression: "ruleForm.isLimited",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtdjg0"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtdlc0"))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.isLimited
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("coupon.list.creatCoupon.5u3io9xtdnw0"),
                        prop: "total",
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          min: 1,
                          max: 9999,
                          disabled: _vm.$route.params.edit ? true : false,
                        },
                        model: {
                          value: _vm.ruleForm.total,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "total",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.total",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("coupon.list.creatCoupon.5u3io9xtdpc0"),
                    prop: "sort",
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: {
                      min: 1,
                      max: 9999,
                      label: _vm.$t("coupon.list.creatCoupon.5u3io9xtdpc0"),
                      disabled: _vm.$route.params.edit ? true : false,
                    },
                    model: {
                      value: _vm.ruleForm.sort,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "sort",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("coupon.list.creatCoupon.5u3io9xtdqk0"),
                    prop: "status",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.$route.params.edit ? true : false,
                      },
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtds40"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v(
                          _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtduw0"))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: [
                            "merchant:coupon:save",
                            "merchant:coupon:product:join:edit",
                          ],
                          expression:
                            "['merchant:coupon:save', 'merchant:coupon:product:join:edit']",
                        },
                      ],
                      attrs: {
                        size: "mini",
                        type: "primary",
                        loading: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("coupon.list.creatCoupon.5u3io9xtdxc0"))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }