"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterIsPromoter = filterIsPromoter;
exports.levelFilter = levelFilter;
exports.registerTypeFilter = registerTypeFilter;
//會員過濾器

/**
 * 等級
 */
function levelFilter(status) {
  if (!status) {
    return '';
  }
  var arrayList = JSON.parse(localStorage.getItem('levelKey'));
  var array = arrayList.filter(function (item) {
    return status === item.id;
  });
  if (array.length) {
    return array[0].name;
  } else {
    return '';
  }
}

/**
 * 用户註冊類型
 */
function registerTypeFilter(status) {
  var statusMap = {
    wechat: '公眾號',
    routine: '小程序',
    h5: 'H5',
    iosWx: '微信ios',
    androidWx: '微信安卓',
    ios: 'ios'
  };
  return statusMap[status];
}

/**
 * 用户類型
 */
function filterIsPromoter(status) {
  var statusMap = {
    true: '推廣員',
    false: '普通用户'
  };
  return statusMap[status];
}