"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _creatComment = _interopRequireDefault(require("./creatComment.vue"));
var _product = require("@/api/product");
var _index = require("@/utils/index");
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'StoreComment',
  filters: {
    formatDate: function formatDate(time) {
      if (time !== 0) {
        var date = new Date(time * 1000);
        return (0, _index.formatDates)(date, 'yyyy-MM-dd hh:mm');
      }
    }
  },
  directives: {
    // 计算是否滚动到最下面
    selectLoadMore: {
      bind: function bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        var SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          if (this.scrollHeight - this.scrollTop < this.clientHeight + 1) {
            binding.value();
          }
        });
      }
    }
  },
  components: {
    creatComment: _creatComment.default
  },
  data: function data() {
    return {
      fromList: this.$constants.fromList,
      tableData: {
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        isReply: '',
        dateLimit: '',
        star: null,
        nickname: '',
        productSearch: ''
      },
      timeVal: [],
      loading: false,
      dialogVisible: false,
      timer: ''
    };
  },
  watch: {
    $route: function $route(to, from) {
      this.getList();
    }
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['merchant:product:reply:page:list'])) this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    seachList: function seachList() {
      this.dialogVisible = false;
      this.tableFrom.page = 1;
      this.getList();
    },
    // 回复
    reply: function reply(id) {
      var _this = this;
      this.$modalPrompt('textarea', this.$t('product.comment.index.5u2qzkf50a80')).then(function (V) {
        (0, _product.replyCommentApi)({
          id: id,
          merchantReplyContent: V
        }).then(function (res) {
          _this.$message({
            type: 'success',
            message: _this.$t('product.comment.index.5u2qzkf50u00')
          });
          _this.getList();
        });
      });
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
    },
    add: function add() {
      this.dialogVisible = true;
      this.timer = new Date().getTime();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.$refs.formValidate.pics = [];
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this2 = this;
      this.$modalSure().then(function () {
        (0, _product.replyDeleteApi)(id).then(function () {
          _this2.$message.success(_this2.$t('product.comment.index.5u2qzkf50wc0'));
          // this.tableData.data.splice(idx, 1);
          _this2.getList();
        });
      });
    },
    // 列表
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      if (this.tableFrom.star === 0) this.tableFrom.star = null;
      (0, _product.replyListApi)(this.tableFrom).then(function (res) {
        _this3.tableData = res;
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};