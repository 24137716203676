"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemSetting = require("@/api/systemSetting");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UploadFile',
  props: {
    value: {}
  },
  data: function data() {
    return {
      myHeaders: {
        'X-Token': (0, _auth.getToken)()
      },
      url: '',
      fileType: ''
    };
  },
  beforeMount: function beforeMount() {
    if (this.value) {
      this.url = this.value;
      this.getFileType(this.value);
    }
  },
  methods: {
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this = this;
      var formData = new FormData();
      var data = {
        model: this.$route.path.split('/')[1],
        pid: 10
      };
      formData.append('multipart', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _systemSetting.fileFileApi)(formData, data).then(function (res) {
        loading.close();
        _this.url = res.url;
        _this.getFileType(res.url);
        _this.$emit('input', _this.url);
        _this.$message.success('上传成功');
      }).catch(function (res) {
        loading.close();
      });
    },
    getFileType: function getFileType(file) {
      var first = file.lastIndexOf('.'); //取到文件名开始到最后一个点的长度
      var namelength = file.length; //取到文件名长度
      var filesuffix = file.substring(first + 1, namelength); //截取获得后缀名
      this.fileType = filesuffix.toUpperCase();
    }
  }
};