"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _creatTemplates = _interopRequireDefault(require("./creatTemplates"));
var logistics = _interopRequireWildcard(require("@/api/logistics.js"));
var _permission = require("@/utils/permission");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'ShippingTemplates',
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        0: '全国包邮',
        1: '部分包邮',
        2: '自定义'
      };
      return statusMap[status];
    },
    typeFilter: function typeFilter(status) {
      var statusMap = {
        0: '无',
        1: '按件数',
        2: '按重量',
        3: '按体积'
      };
      return statusMap[status];
    }
  },
  components: {
    CreatTemplates: _creatTemplates.default
  },
  data: function data() {
    return {
      isShow: false,
      dialogVisible: false,
      form: {
        keywords: ''
      },
      tableData: '',
      page: 1,
      limit: 20,
      loading: false
    };
  },
  created: function created() {
    if ((0, _permission.checkPermi)(['merchant:shipping:templates:list'])) this.getDataList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 添加
    handleSubmit: function handleSubmit() {
      this.$refs.addTemplates.dialogVisible = true;
      if (!localStorage.getItem('cityList')) this.$refs.addTemplates.getCityList();
      this.$refs.addTemplates.changType(0);
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getDataList();
    },
    // 分页
    pageChange: function pageChange(e) {
      this.page = e;
      this.getDataList();
    },
    handleSizeChange: function handleSizeChange(e) {
      this.limit = e;
      this.getDataList();
    },
    // 数据列表
    getDataList: function getDataList() {
      var _this = this;
      this.loading = true;
      logistics.shippingTemplatesList({
        keywords: encodeURIComponent(this.form.keywords),
        page: this.page,
        limit: this.limit
      }).then(function (res) {
        _this.loading = false;
        _this.tableData = res;
      });
    },
    // 编辑
    bindEdit: function bindEdit(item) {
      if (!localStorage.getItem('cityList')) this.$refs.addTemplates.getCityList();
      this.$refs.addTemplates.getInfo(item.id, item.appoint);
      this.$refs.addTemplates.changType(1);
    },
    // 删除
    bindDelete: function bindDelete(item) {
      var _this2 = this;
      this.$modalSure().then(function () {
        logistics.shippingDetete({
          id: item.id
        }).then(function (res) {
          _this2.$message.success('删除成功');
          _this2.$store.commit('product/SET_ShippingTemplates', []);
          _this2.getDataList();
        });
      });
      // logistics.shippingDetete()
    },
    getList: function getList() {
      this.getDataList();
    }
  }
};