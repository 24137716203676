"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var store = _interopRequireWildcard(require("@/api/product"));
var _permission = require("@/utils/permission");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    var _this = this;
    var validateGuarantee = function validateGuarantee(rule, value, callback) {
      if (_this.guaranteeChanged.length === 0) {
        callback(new Error(_this.$t('product.guarantee.index.5u2r0mgn8vo0')));
      } else {
        callback();
      }
    };
    return {
      listLoading: false,
      keyNum: 0,
      dialogVisible: false,
      guaranteeForm: {
        name: '',
        id: 0,
        gids: ''
      },
      guaranteeList: [],
      guaranteeListNew: [],
      tableData: {
        data: []
      },
      rules: {
        name: [{
          required: true,
          message: this.$t('product.guarantee.index.5u2r0mgn9080'),
          trigger: 'blur'
        }],
        guaranteeList: [{
          required: true,
          validator: validateGuarantee,
          trigger: 'change'
        }]
      },
      guaranteeChanged: [],
      isDisabled: false
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['merchant:product:guarantee:group:list'])) this.getList();
    if ((0, _permission.checkPermi)(['merchant:plat:product:guarantee:list'])) this.getProductGuarantee();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    selectable: function selectable(row, index) {
      if (this.isDisabled) return false;
      if (row.isShow) {
        return true;
      } else {
        return false;
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.guaranteeChanged = val;
    },
    // 保障服务列表
    getProductGuarantee: function getProductGuarantee() {
      var _this2 = this;
      store.productGuaranteeApi().then(function (res) {
        _this2.guaranteeList = res;
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.guaranteeForm = {
        name: '',
        id: 0,
        gids: ''
      };
      this.guaranteeChanged = [];
      this.$refs.multipleTable.clearSelection();
      this.$refs['guaranteeForm'].resetFields();
    },
    // 列表
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      store.guaranteeListApi().then(function (res) {
        _this3.tableData.data = res;
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handlerOpenEdit: function handlerOpenEdit(isCreate, editDate, info) {
      var _this4 = this;
      var list = [];
      this.guaranteeListNew = _toConsumableArray(this.guaranteeList);
      this.isDisabled = !!info;
      if (isCreate === 1) {
        this.guaranteeForm = Object.assign({}, editDate);
        this.guaranteeListNew.map(function (item) {
          editDate.guaranteeList.map(function (obj) {
            if (obj.gid === item.id && obj.isShow) list.push(item);
          });
        });
        if (list) {
          list.forEach(function (row) {
            _this4.$nextTick(function () {
              _this4.$refs.multipleTable.toggleRowSelection(row, true);
            });
          });
        }
      } else {
        list = this.guaranteeListNew.filter(function (item) {
          return item.isShow;
        });
        this.guaranteeListNew = list;
      }
      this.dialogVisible = true;
    },
    close: function close() {
      this.dialogVisible = false;
      this.guaranteeForm.name = '';
      this.getList();
    },
    submitForm: function submitForm(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var ids = [];
          _this5.guaranteeChanged.map(function (item) {
            ids.push(item.id);
          });
          _this5.$delete(_this5.guaranteeForm, 'guaranteeList');
          _this5.$delete(_this5.guaranteeForm, 'createTime');
          _this5.guaranteeForm.gids = ids.toString();
          !_this5.guaranteeForm.id ? store.guaranteeAddApi(_this5.guaranteeForm).then(function (res) {
            _this5.$message.success(_this5.$t('product.guarantee.index.5u2r0mgn94s0'));
            _this5.close();
          }).catch(function () {
            _this5.loading = false;
          }) : store.guaranteeUpdateApi(_this5.guaranteeForm).then(function (res) {
            _this5.$message.success(_this5.$t('product.guarantee.index.5u2r0mgn94s0'));
            _this5.close();
          }).catch(function () {
            _this5.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handlerOpenDel: function handlerOpenDel(rowData) {
      var _this6 = this;
      this.$modalSure(this.$t('product.guarantee.index.5u2r0mgn98w0')).then(function () {
        store.guaranteeDeleteApi(rowData.id).then(function (data) {
          _this6.$message.success(_this6.$t('product.guarantee.index.5u2r0mgn9d00'));
          _this6.getList();
        });
      });
    }
  }
};