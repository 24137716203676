var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["merchant:pay:component:product:draft:list"],
                      expression:
                        "['merchant:pay:component:product:draft:list']",
                    },
                  ],
                  staticClass: "container",
                },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品名称：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入商品名称",
                                clearable: "",
                              },
                              model: {
                                value: _vm.search,
                                callback: function ($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search",
                              },
                            },
                            [
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: [
                                      "merchant:pay:component:product:draft:list",
                                    ],
                                    expression:
                                      "['merchant:pay:component:product:draft:list']",
                                  },
                                ],
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { path: "/videoChannel/creatVideoChannel" } } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["merchant:pay:component:product:draft:add"],
                          expression:
                            "['merchant:pay:component:product:draft:add']",
                        },
                      ],
                      staticClass: "mr10",
                      attrs: { size: "small", type: "primary" },
                    },
                    [_vm._v("添加视频号商品")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "header-cell-style": { fontWeight: "bold" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "Id", width: "40" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "名称", prop: "title", "min-width": "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              trigger: "hover",
                              placement: "right",
                              "open-delay": 800,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "text_overflow",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(scope.row.title))]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "pup_card" }, [
                              _vm._v(_vm._s(scope.row.title)),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品图", "min-width": "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: JSON.parse(scope.row.headImg)[0],
                                "preview-src-list": JSON.parse(
                                  scope.row.headImg
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "类目",
                  "min-width": "150",
                  prop: "thirdCatName",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sales",
                  label: "销量",
                  "min-width": "80",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "stock",
                  label: "库存",
                  "min-width": "80",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "微信审核",
                  "min-width": "90",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("editStatusFilter")(scope.row.editStatus)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.editStatus === 3,
                                expression: "scope.row.editStatus === 3",
                              },
                            ],
                            staticClass: "fontColor3",
                          },
                          [
                            _vm._v(
                              "\n            拒绝原因：" +
                                _vm._s(scope.row.platformStatusReason) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "平台审核",
                  "min-width": "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("platformStatusFilter")(
                                scope.row.platformEditStatus
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.platformEditStatus === 3,
                                expression:
                                  "scope.row.platformEditStatus === 3",
                              },
                            ],
                            staticClass: "fontColor3",
                          },
                          [
                            _vm._v(
                              "\n            拒绝原因：" +
                                _vm._s(scope.row.platformStatusReason) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  prop: "addTime",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "130",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "router-link",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  scope.row.platformEditStatus === 1 ||
                                  scope.row.platformEditStatus === 3 ||
                                  scope.row.editStatus === 3,
                                expression:
                                  "\n              scope.row.platformEditStatus === 1 || scope.row.platformEditStatus === 3 || scope.row.editStatus === 3\n            ",
                              },
                            ],
                            attrs: {
                              to: {
                                path:
                                  "/videoChannel/creatVideoChannel/" +
                                  scope.row.id,
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: [
                                      "merchant:pay:component:product:draft:update",
                                    ],
                                    expression:
                                      "['merchant:pay:component:product:draft:update']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path:
                                  "/videoChannel/creatVideoChannel/" +
                                  scope.row.id +
                                  "/1",
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: [
                                      "merchant:pay:component:product:draft:info",
                                    ],
                                    expression:
                                      "['merchant:pay:component:product:draft:info']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                              },
                              [_vm._v("详情")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.platformEditStatus !== 2,
                                expression:
                                  "scope.row.platformEditStatus !== 2",
                              },
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [
                                  "merchant:pay:component:product:draft:delete",
                                ],
                                expression:
                                  "['merchant:pay:component:product:draft:delete']",
                              },
                            ],
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerOpenDel(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  scope.row.platformEditStatus === 1 ||
                                  scope.row.platformEditStatus === 3,
                                expression:
                                  "scope.row.platformEditStatus === 1 || scope.row.platformEditStatus === 3",
                              },
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [
                                  "merchant:pay:component:product:draft:review",
                                ],
                                expression:
                                  "['merchant:pay:component:product:draft:review']",
                              },
                            ],
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerReview(scope.row, 2)
                              },
                            },
                          },
                          [_vm._v("提审")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.platformEditStatus === 2,
                                expression:
                                  "scope.row.platformEditStatus === 2",
                              },
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [
                                  "merchant:pay:component:product:draft:review",
                                ],
                                expression:
                                  "['merchant:pay:component:product:draft:review']",
                              },
                            ],
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerReview(scope.row, 1)
                              },
                            },
                          },
                          [_vm._v("撤回申请")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block mb20" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }