"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
var storeApi = _interopRequireWildcard(require("@/api/product.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "edit"
  props: {
    prent: {
      type: Object,
      required: true
    },
    isCreate: {
      type: Number,
      default: 0
    },
    editData: {
      type: Object
    },
    biztype: {
      type: Object,
      required: true
    },
    allTreeList: {
      type: Array
    }
  },
  data: function data() {
    return {
      loadingBtn: false,
      constants: this.$constants,
      editPram: {
        icon: null,
        name: null,
        pid: 0,
        sort: 0,
        id: 0
      },
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'childList',
        expandTrigger: 'hover',
        checkStrictly: true,
        emitPath: false
      },
      parentOptions: []
    };
  },
  mounted: function mounted() {
    this.initEditData();
  },
  methods: {
    change: function change() {
      //this.editPram.level = parseInt(this.$refs['cascader'].getCheckedNodes()[0].level) + 1
    },
    // 点击图标
    addIcon: function addIcon() {
      var _this = this;
      _this.$modalIcon(function (icon) {
        _this.editPram.extra = icon;
      });
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      var attr = [];
      this.$modalUpload(function (img) {
        if (tit === '1' && !num) {
          _this.editPram.icon = img[0].sattDir;
        }
        if (tit === '2' && !num) {
          img.map(function (item) {
            attr.push(item.attachment_src);
            _this.formValidate.slider_image.push(item);
          });
        }
      }, tit, 'store');
    },
    close: function close() {
      this.$emit('hideEditDialog');
    },
    initEditData: function initEditData() {
      // this.parentOptions = this.changeNodes([...this.allTreeList])
      // this.addTreeListLabelForCasCard(this.parentOptions, 'childList')
      var _this$editData = this.editData,
        icon = _this$editData.icon,
        name = _this$editData.name,
        pid = _this$editData.pid,
        sort = _this$editData.sort,
        id = _this$editData.id;
      if (this.isCreate === 1) {
        this.editPram.icon = icon;
        this.editPram.name = name;
        this.editPram.pid = pid;
        this.editPram.sort = sort;
        this.editPram.id = id;
      } else {
        this.editPram.pid = this.prent.id;
      }
    },
    handlerSubmit: function handlerSubmit(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (!valid) return;
        _this2.handlerSaveOrUpdate(_this2.isCreate === 0);
      });
    },
    handlerSaveOrUpdate: function handlerSaveOrUpdate(isSave) {
      var _this3 = this;
      if (isSave) {
        this.loadingBtn = true;
        storeApi.productCategoryAddApi(this.editPram).then(function (data) {
          _this3.$emit('hideEditDialog');
          _this3.$message.success(_this3.$t('components.Category.edit.5u3irqtvzpo0'));
          _this3.$store.commit('product/SET_MerProductClassify', []);
          _this3.loadingBtn = false;
        }).catch(function () {
          _this3.loadingBtn = false;
        });
      } else {
        this.loadingBtn = true;
        storeApi.productCategoryUpdateApi(this.editPram).then(function (data) {
          _this3.$emit('hideEditDialog');
          _this3.$message.success(_this3.$t('components.Category.edit.5u3irqtvzrg0'));
          _this3.$store.commit('product/SET_MerProductClassify', []);
          _this3.loadingBtn = false;
        }).catch(function () {
          _this3.loadingBtn = false;
        });
      }
    }
  }
};