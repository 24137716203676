var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.list,
                "header-cell-style": { fontWeight: "bold" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "adminId",
                  label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq1zw0"),
                  width: "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "adminAccount",
                  label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq2oo0"),
                  width: "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq2ro0"),
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tag",
                          { attrs: { type: scope.row.status ? "danger" : "" } },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.status
                                  ? _vm.$t(
                                      "maintain.sensitiveList.index.5u3fiaqq2to0"
                                    )
                                  : _vm.$t(
                                      "maintain.sensitiveList.index.5u3fiaqq2v80"
                                    )
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "description",
                  label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq2ww0"),
                  width: "140",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq2yg0"),
                  width: "120",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "ip",
                  label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq30c0"),
                  width: "120",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "method",
                  label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq3200"),
                  width: "180",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "url",
                  label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq33w0"),
                  width: "180",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "methodType",
                  label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq35c0"),
                  width: "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "requestMethod",
                  label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq36s0"),
                  width: "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "requestParam",
                  label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq3880"),
                  width: "150",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "result",
                  label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq3aw0"),
                  width: "180",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "errorMsg",
                  label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq3co0"),
                  width: "180",
                  "show-overflow-tooltip": true,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 30, 40, 60],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }