"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemadmin = require("@/api/systemadmin");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      tableData: {},
      tableFrom: {
        page: 1,
        limit: 20
      },
      listLoading: false
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['merchant:log:sensitive:list'])) this.getLogList();
  },
  methods: {
    getLogList: function getLogList() {
      var _this = this;
      this.listLoading = true;
      (0, _systemadmin.sensitiveListApi)(this.tableFrom).then(function (res) {
        _this.tableData = res;
        _this.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getLogList();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getLogList();
    }
  }
};