var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "pram",
          attrs: { model: _vm.pram, rules: _vm.rules, "label-width": "100px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq2oo0"),
                prop: "account",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isCreate === 1,
                  placeholder: _vm.$t(
                    "maintain.sensitiveList.index.5u3fiaqq2oo0"
                  ),
                },
                model: {
                  value: _vm.pram.account,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.pram,
                      "account",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "pram.account",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq2oo02"),
                prop: "realName",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t(
                    "maintain.sensitiveList.index.5u3fiaqq2oo04"
                  ),
                },
                model: {
                  value: _vm.pram.realName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.pram,
                      "realName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "pram.realName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("maintain.sensitiveList.index.5u3fiaqq2oo03"),
                prop: "roles",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "身份", clearable: "", multiple: "" },
                  model: {
                    value: _vm.pram.roles,
                    callback: function ($$v) {
                      _vm.$set(_vm.pram, "roles", $$v)
                    },
                    expression: "pram.roles",
                  },
                },
                _vm._l(_vm.roleList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.roleName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "space-between",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("coupon.list.creatCoupon.5u3io9xtdqk0"),
                  },
                },
                [
                  _c("el-switch", {
                    attrs: { "active-value": true, "inactive-value": false },
                    model: {
                      value: _vm.pram.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.pram, "status", $$v)
                      },
                      expression: "pram.status",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-bottom": "22px",
                    "margin-right": "16px",
                    "font-size": "14px",
                    color: "red",
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("maintain.sensitiveList.index.5u3fiaqq2oo05"))
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["merchant:admin:update"],
                      expression: "['merchant:admin:update']",
                    },
                  ],
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.handlerSubmit("pram")
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.isCreate === 0 ? _vm.$t("operateBtn.confirm") : "更新"
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v(_vm._s(_vm.$t("operateBtn.cancel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }