"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = modalPrompt;
/**
 * $prompt一行内容input提交封装
 * @param inputType input type 类型
 * @param title 标题
 * @param val 回显的值
 * @returns {Promise<any>}
 */
function modalPrompt(inputType, title, val) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.$prompt("".concat(title), {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputErrorMessage: "\u8BF7\u8F93\u5165".concat(title),
      inputType: inputType,
      inputValue: val ? val : '',
      showClose: false,
      closeOnClickModal: false,
      inputPlaceholder: "\u8BF7\u8F93\u5165".concat(title),
      inputValidator: function inputValidator(value) {
        if (!value) return '输入不能为空';
      },
      beforeClose: function beforeClose(action, instance, done) {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = '执行中...';
          setTimeout(function () {
            done();
            setTimeout(function () {
              instance.confirmButtonLoading = false;
            }, 300);
          }, 3000);
        } else {
          done();
        }
      }
    }).then(function (_ref) {
      var value = _ref.value;
      resolve(value);
      // this.ruleForm.reason = value;
      // this.submit();
    }).catch(function () {
      _this.$message.info('取消输入');
    });
  });
}