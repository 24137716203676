"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = modalSure;
/**
 * 再次确定弹窗组件
 * @param title 标题
 * @returns {Promise<any>}
 */
function modalSure(title) {
  var _this = this;
  return new Promise(function (resolve, reject) {
    _this.$confirm("".concat(title || '确定永久删除该数据'), '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      resolve();
    }).catch(function () {
      reject();
      _this.$message({
        type: 'info',
        message: '已取消'
      });
    });
  });
}