"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _orderDetail = _interopRequireDefault(require("@/components/orderDetail"));
var _elementUi = require("element-ui");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _utils = require("@/utils");
var _product = require("@/api/product");
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'orderRefund',
  components: {
    detailsFrom: _orderDetail.default
  },
  data: function data() {
    return {
      RefuseVisible: false,
      RefuseData: {},
      orderNo: '',
      refundVisible: false,
      refundData: {},
      dialogVisibleJI: false,
      tableDataLog: {
        data: [],
        total: 0
      },
      tableFromLog: {
        page: 1,
        limit: 10,
        orderNo: 0
      },
      LogLoading: false,
      isCreate: 1,
      editData: null,
      dialogVisible: false,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        refundStatus: '9',
        dateLimit: '',
        orderNo: '',
        refundOrderNo: '',
        page: 1,
        limit: 20
      },
      orderChartType: {},
      timeVal: [],
      fromList: this.$constants.fromList,
      selectionList: [],
      ids: '',
      orderids: '',
      cardLists: [],
      isWriteOff: (0, _utils.isWriteOff)(),
      proType: 0,
      active: false,
      card_select_show: false,
      checkAll: false,
      checkedCities: [this.$t('order.refund.index.5u3cvdromjo0'), this.$t('order.refund.index.5u3cvdroml00'), this.$t('order.refund.index.5u3cvdrommo0'), this.$t('order.refund.index.5u3cvdromqk0'), this.$t('order.refund.index.5u3cvdromt40'), this.$t('order.refund.index.5u3cvdromuc0')],
      columnData: [this.$t('order.refund.index.5u3cvdromjo0'), this.$t('order.refund.index.5u3cvdroml00'), this.$t('order.refund.index.5u3cvdrommo0'), this.$t('order.refund.index.5u3cvdromqk0'), this.$t('order.refund.index.5u3cvdromt40'), this.$t('order.refund.index.5u3cvdromuc0')],
      isIndeterminate: true
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['merchant:refund:order:page:list'])) this.getList();
    if ((0, _permission.checkPermi)(['merchant:refund:order:status:num'])) this.getOrderStatusNum();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    resetFormRefundhandler: function resetFormRefundhandler() {
      this.refundVisible = false;
    },
    resetFormRefusehand: function resetFormRefusehand() {
      this.RefuseVisible = false;
    },
    resetForm: function resetForm(formValue) {
      this.dialogVisible = false;
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
    },
    // 拒绝退款
    RefusehandleClose: function RefusehandleClose() {
      this.RefuseVisible = false;
    },
    onOrderRefuse: function onOrderRefuse(row) {
      var _this = this;
      this.$modalPrompt('textarea', this.$t('order.refund.index.5u3cvdron4w0')).then(function (V) {
        (0, _order.orderRefuseApi)({
          reason: V,
          refundOrderNo: row.refundOrderNo
        }).then(function () {
          _this.$message.success(_this.$t('order.refund.index.5u3cvdron780'));
          _this.getList();
        });
      });
    },
    RefusehandlerSubmit: function RefusehandlerSubmit(formValue) {
      var _this2 = this;
      (0, _order.orderRefuseApi)({
        refundOrderNo: formValue.refundOrderNo,
        reason: formValue.reason
      }).then(function (data) {
        _this2.$message.success(_this2.$t('order.refund.index.5u3cvdron780'));
        _this2.RefuseVisible = false;
        _this2.getList();
      });
    },
    // 立即退款
    refundhandleClose: function refundhandleClose() {
      this.refundVisible = false;
    },
    onOrderRefund: function onOrderRefund(row) {
      var _this3 = this;
      _elementUi.Loading.service();
      this.$modalSure(this.$t('order.refund.index.5u3cvdron8g0')).then(function () {
        (0, _order.orderRefundApi)({
          refundOrderNo: row.refundOrderNo
        }).then(function (data) {
          _this3.$message.success(_this3.$t('order.refund.index.5u3cvdron780'));
          _this3.tableFrom.page = 1;
          _this3.getList();
          _elementUi.Loading.service().close();
        }).catch(function (err) {
          _this3.$message.error('错误');
          _this3.getList();
          _elementUi.Loading.service().close();
        });
      });
    },
    refundhandlerSubmit: function refundhandlerSubmit(formValue) {
      var _this4 = this;
      (0, _order.orderRefundApi)({
        refundOrderNo: this.orderids
      }).then(function (data) {
        _this4.$message.success(_this4.$t('order.refund.index.5u3cvdron780'));
        _this4.refundVisible = false;
        _this4.tableFrom.page = 1;
        _this4.getList();
      });
    },
    // 详情
    onOrderDetails: function onOrderDetails(row) {
      this.orderNo = row.orderNo;
      this.$refs.orderDetail.getDetail(row.orderNo);
      this.$refs.orderDetail.getRefundOrderDetail(row.refundOrderNo);
      this.$refs.orderDetail.getOrderInvoiceList(row.orderNo);
      this.$refs.orderDetail.dialogVisible = true;
    },
    // 订单记录
    onOrderLog: function onOrderLog(id) {
      var _this5 = this;
      this.dialogVisibleJI = true;
      this.LogLoading = true;
      this.tableFromLog.orderNo = id;
      (0, _order.orderLogApi)(this.tableFromLog).then(function (res) {
        _this5.tableDataLog.data = res.list;
        _this5.tableDataLog.total = res.total;
        _this5.LogLoading = false;
      }).catch(function () {
        _this5.LogLoading = false;
      });
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.onOrderLog();
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.onOrderLog();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 备注
    onOrderMark: function onOrderMark(row) {
      var _this6 = this;
      this.$modalPrompt('textarea', this.$t('order.refund.index.5u3cvdron9g0'), row.merRemark).then(function (V) {
        (0, _order.refundMarkApi)({
          remark: V,
          refundOrderNo: row.refundOrderNo
        }).then(function () {
          _this6.$message.success(_this6.$t('order.refund.index.5u3cvdron780'));
          _this6.getList();
        });
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectionList = val;
      var data = [];
      this.selectionList.map(function (item) {
        data.push(item.orderNo);
      });
      this.ids = data.join(',');
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
      // this.getOrderListData();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
      // this.getOrderListData();
    },
    // 列表
    getList: function getList() {
      var _this7 = this;
      this.listLoading = true;
      (0, _order.refundListApi)(this.tableFrom).then(function (res) {
        _this7.tableData.data = res.list || [];
        _this7.tableData.total = res.total;
        _this7.listLoading = false;
        _this7.checkedCities = _this7.$cache.local.has('order_stroge') ? _this7.$cache.local.getJSON('order_stroge') : _this7.checkedCities;
      }).catch(function () {
        _this7.listLoading = false;
      });
    },
    // 获取各状态数量
    getOrderStatusNum: function getOrderStatusNum() {
      var _this8 = this;
      (0, _order.refundStatusNumApi)({
        dateLimit: this.tableFrom.dateLimit
      }).then(function (res) {
        _this8.orderChartType = res;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    exports: function exports() {
      var data = {
        dateLimit: this.tableFrom.dateLimit,
        orderNo: this.tableFrom.orderNo,
        refundStatus: this.tableFrom.status,
        type: this.tableFrom.type
      };
      (0, _product.orderExcelApi)(data).then(function (res) {
        window.open(res.fileName);
      });
    },
    renderHeader: function renderHeader(h) {
      var _this9 = this;
      return h("p", [h("span", {
        "style": "padding-right:5px;"
      }, [this.$t('order.refund.index.5u3cvdromvk0')]), h("i", {
        "class": "el-icon-setting",
        "on": {
          "click": function click() {
            return _this9.handleAddItem();
          }
        }
      })]);
    },
    handleAddItem: function handleAddItem() {
      if (this.card_select_show) {
        this.$set(this, 'card_select_show', false);
      } else if (!this.card_select_show) {
        this.$set(this, 'card_select_show', true);
      }
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.checkedCities = val ? this.columnData : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.columnData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.columnData.length;
    },
    checkSave: function checkSave() {
      this.$set(this, 'card_select_show', false);
      this.$modal.loading(this.$t('order.refund.index.5u3cvdronao0'));
      this.$cache.local.setJSON('order_stroge', this.checkedCities);
      setTimeout(this.$modal.closeLoading(), 1000);
    },
    //打印小票
    onOrderPrint: function onOrderPrint(data) {
      var _this10 = this;
      (0, _order.orderPrint)(data.orderNo).then(function (res) {
        _this10.$modal.msgSuccess(_this10.$t('order.refund.index.5u3cvdronbs0'));
      }).catch(function (error) {
        _this10.$modal.msgError(error.message);
      });
    }
  }
};